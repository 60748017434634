import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  ceCerts: [],
};

const ceCertsSlice = createSlice({
  name: "ceCerts",
  initialState,
  reducers: {
    fetchCeCerts(state, action) {
        state.ceCerts = _.orderBy(action.payload, ["dateCertificate"], "desc");
    }
  },
});

export const { fetchCeCerts } = ceCertsSlice.actions;

export default ceCertsSlice.reducer;
