export function sellersPropertyDisclosureCommercial() {
return [   //2024 Release 2024-06-24 20:51:41
{
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 200,
      height: 30,
    }
   ,
   {
   page: 0,
   name: "Text3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 350.911,
   left: 100.146,
   width: 449.783,
   height: 14.800
}
,
{
   page: 0,
   name: "Text4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 368.438,
   left: 110.619,
   width: 439.310,
   height: 14.800
}
,
{
   page: 0,
   name: "Text5", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 386.911,
   left: 101.455,
   width: 448.474,
   height: 14.800
}
,
{
   page: 0,
   name: "Text6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 404.511,
   left: 119.128,
   width: 431.456,
   height: 14.800
}
,
{
   page: 0,
   name: "Text7", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 423.565,
   left: 198.328,
   width: 351.601,
   height: 14.800
}
,
{
   page: 0,
   name: "Check Box19", 
   isText: false,
   type: "checkbox",
   top: 579.275,
   left: 234.328,
   width: 18.000,
   height: 18.000
}
,
{
   page: 0,
   name: "YesStructural", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 575.105,
   left: 260.913,
   width: 312.579,
   height: 44.429
}
,
{
   page: 0,
   name: "Check Box20", 
   isText: false,
   type: "checkbox",
   top: 627.057,
   left: 234.328,
   width: 18.000,
   height: 18.000
}
,
{
   page: 0,
   name: "YesMoisture andor water", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 625.156,
   left: 260.793,
   width: 312.579,
   height: 31.909
}
,
{
   page: 0,
   name: "Check Box21", 
   isText: false,
   type: "checkbox",
   top: 664.367,
   left: 234.328,
   width: 18.000,
   height: 18.000
}
,
{
   page: 0,
   name: "YesDamage due to termites other insects birds animals or rodents", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 661.222,
   left: 260.793,
   width: 312.579,
   height: 33.218
}
,
{
   page: 0,
   name: "Check Box22", 
   isText: false,
   type: "checkbox",
   top: 701.6758,
   left: 234.328,
   width: 18.000,
   height: 18.0000
}
,
{
   page: 0,
   name: "YesDamage due to hail wind fire flood or other casualty", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 698.5963,
   left: 260.793,
   width: 312.579,
   height: 32.5637
}
,
{
   page: 1,
   name: "Cracks heaving or settling", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 43.684,
   left: 260.793,
   width: 311.925,
   height: 33.382
}
,
{
   page: 1,
   name: "Exterior wall or window", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 80.404,
   left: 260.793,
   width: 311.925,
   height: 32.727
}
,
{
   page: 1,
   name: "Exterior Artificial Stucco EIFS", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 117.124,
   left: 260.793,
   width: 311.925,
   height: 33.381
}
,
{
   page: 1,
   name: "Subfloors", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 153.844,
   left: 260.793,
   width: 311.925,
   height: 32.072
}
,
{
   page: 1,
   name: "Subfloors9", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 191.629,
   left: 68.52,
   width: 158.04,
   height: 21.971
}
,
{
   page: 1,
   name: "Subfloors10", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 216.349,
   left: 68.52,
   width: 158.04,
   height: 21.971
}
,
{
   page: 1,
   name: "CommentsRoof leak", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 307.876,
   left: 260.16,
   width: 317.16,
   height: 32.564
}
,
{
   page: 1,
   name: "CommentsDamage to roof", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 345.251,
   left: 260.16,
   width: 317.16,
   height: 31.909
}
,
{
   page: 1,
   name: "CommentsSkylight", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 380.007,
   left: 260.16,
   width: 317.16,
   height: 33.873
}
,
{
   page: 1,
   name: "CommentsGutter or downspout", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 417.382,
   left: 260.16,
   width: 317.16,
   height: 33.218
}
,
{
   page: 1,
   name: "CommentsOther roof problems issues or concerns", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 455.411,
   left: 260.16,
   width: 317.16,
   height: 31.909
}
,
{
   page: 1,
   name: "Other roof problems issues or concerns6", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 492.095,
   left: 68.64,
   width: 157.80,
   height: 30.600
}
,
{
   page: 1,
   name: "Comments6", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 491.44,
   left: 260.16,
   width: 317.16,
   height: 31.909
}
,
{
   page: 1,
   name: "Other roof problems issues or concerns7", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 527.16,
   left: 69.0546,
   width: 157.6254,
   height: 21.545
}
,
{
   page: 1,
   name: "Comments7", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 527.16,
   left: 259.68,
   width: 317.64,
   height: 20.891
}
,
{
   page: 1,
   name: "Transferable", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 594.72,
   left: 73.44,
   width: 145.746,
   height: 12.295
}
,
{
   page: 1,
   name: "CommentsRoof under warranty until Transferable YES NO", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 586.92,
   left: 260.64,
   width: 316.68,
   height: 49.996
}
,
{
   page: 1,
   name: "CommentsRoof work done while under current roof warranty", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 641.36,
   left: 260.16,
   width: 317.16,
   height: 30.12
}
,
{
   page: 1,
   name: "Age", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 687.24,
   left: 73.44,
   width: 133.44,
   height: 11.64
}
,
{
   page: 1,
   name: "undefined", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 702.36,
   left: 93.12,
   width: 130.56,
   height: 11.64
}
,
{
   page: 1,
   name: "CommentsRoof material Age", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 675.72,
   left: 260.64,
   width: 316.68,
   height: 39.4364
}
,
{
   page: 1,
   name: "Roof material Age11", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 718.44,
   left: 70.3637,
   width: 155.6613,
   height: 20.2364
}
,
{
   page: 1,
   name: "Comments11", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 718.44,
   left: 259.68,
   width: 317.64,
   height: 20.8909
}
,
{
   page: 1,
   name: "Text13", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 190.128,
   left: 260.793,
   width: 311.925,
   height: 22.000
}
,
{
   page: 1,
   name: "Text14", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 215.656,
   left: 260.793,
   width: 311.925,
   height: 22.000
}
,
{
   page: 1,
   name: "Check Box23", 
   isText: false,
   type: "checkbox",
   top: 49.746,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 1,
   name: "Check Box24", 
   isText: false,
   type: "checkbox",
   top: 84.437,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 1,
   name: "Check Box25", 
   isText: false,
   type: "checkbox",
   top: 123.055,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 1,
   name: "Check Box26", 
   isText: false,
   type: "checkbox",
   top: 161.019,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 1,
   name: "Check Box27", 
   isText: false,
   type: "checkbox",
   top: 190.474,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 1,
   name: "Check Box28", 
   isText: false,
   type: "checkbox",
   top: 217.965,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 1,
   name: "Check Box29", 
   isText: false,
   type: "checkbox",
   top: 314.183,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 1,
   name: "Check Box30", 
   isText: false,
   type: "checkbox",
   top: 353.456,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 1,
   name: "Check Box31", 
   isText: false,
   type: "checkbox",
   top: 387.493,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 1,
   name: "Check Box32", 
   isText: false,
   type: "checkbox",
   top: 425.456,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 1,
   name: "Check Box33", 
   isText: false,
   type: "checkbox",
   top: 462.111,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 1,
   name: "Check Box34", 
   isText: false,
   type: "checkbox",
   top: 492.22,
   left: 233.019,
   width: 18.000,
   height: 18.00
}
,
{
   page: 1,
   name: "Check Box35", 
   isText: false,
   type: "checkbox",
   top: 526.257,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 1,
   name: "Check Box36", 
   isText: false,
   type: "checkbox",
   top: 591.712,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 1,
   name: "Check Box37", 
   isText: false,
   type: "checkbox",
   top: 645.385,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 1,
   name: "Check Box38", 
   isText: false,
   type: "checkbox",
   top: 681.385,
   left: 233.019,
   width: 18.000,
   height: 17.9998
}
,
{
   page: 1,
   name: "Check Box39", 
   isText: false,
   type: "checkbox",
   top: 720.6577,
   left: 233.019,
   width: 18.000,
   height: 18.0000
}
,
{
   page: 1,
   name: "Check Box40", 
   isText: false,
   type: "checkbox",
   top: 610.657,
   left: 123.71,
   width: 16.036,
   height: 15.382
}
,
{
   page: 1,
   name: "Check Box41", 
   isText: false,
   type: "checkbox",
   top: 610.657,
   left: 158.128,
   width: 16.036,
   height: 15.382
}
,
{
   page: 2,
   name: "Age if KnownLight fixtures", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 120.72,
   left: 260.16,
   width: 42.84,
   height: 30.60
}
,
{
   page: 2,
   name: "CommentsLight fixtures", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 120.72,
   left: 305.28,
   width: 273.96,
   height: 30.60
}
,
{
   page: 2,
   name: "Age if KnownSwitches  outlets", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 156.785,
   left: 260.16,
   width: 42.84,
   height: 31.255
}
,
{
   page: 2,
   name: "CommentsSwitches  outlets", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 156.131,
   left: 305.28,
   width: 273.96,
   height: 31.909
}
,
{
   page: 2,
   name: "Age if KnownTelecommunications system equipment or service", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 192.851,
   left: 260.16,
   width: 42.84,
   height: 31.909
}
,
{
   page: 2,
   name: "CommentsTelecommunications system equipment or service", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 193.16,
   left: 305.28,
   width: 273.96,
   height: 30.60
}
,
{
   page: 2,
   name: "Age if KnownInside wiring  blockjacks", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 229.88,
   left: 260.16,
   width: 42.84,
   height: 30.60
}
,
{
   page: 2,
   name: "CommentsInside wiring  blockjacks", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 229.571,
   left: 305.28,
   width: 273.96,
   height: 31.909
}
,
{
   page: 2,
   name: "Inside wiring  blockjacks5", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 264.72,
   left: 68.52,
   width: 158.04,
   height: 23.28
}
,
{
   page: 2,
   name: "Age if Known5", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 264.72,
   left: 260.04,
   width: 43.08,
   height: 23.28
}
,
{
   page: 2,
   name: "Comments5", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 264.72,
   left: 305.16,
   width: 274.20,
   height: 22.625
}
,
{
   page: 2,
   name: "Inside wiring  blockjacks6", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 289.44,
   left: 68.52,
   width: 158.04,
   height: 20.662
}
,
{
   page: 2,
   name: "Age if Known6", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 289.44,
   left: 260.04,
   width: 43.08,
   height: 23.28
}
,
{
   page: 2,
   name: "Comments6_2", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 289.44,
   left: 305.16,
   width: 274.20,
   height: 21.316
}
,
{
   page: 2,
   name: "Age if KnownElectrical Service", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 383.6,
   left: 260.16,
   width: 42.84,
   height: 30.12
}
,
{
   page: 2,
   name: "CommentsElectrical Service", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 383.6,
   left: 305.28,
   width: 273.96,
   height: 30.12
}
,
{
   page: 2,
   name: "Age if KnownAluminum wiring at the outlets 110", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 419.64,
   left: 260.16,
   width: 42.84,
   height: 30.12
}
,
{
   page: 2,
   name: "CommentsAluminum wiring at the outlets 110", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 419.64,
   left: 305.28,
   width: 273.96,
   height: 30.12
}
,
{
   page: 2,
   name: "Age if KnownSolar panels Owned Leased", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 454.56,
   left: 260.16,
   width: 42.84,
   height: 30.12
}
,
{
   page: 2,
   name: "CommentsSolar panels Owned Leased", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 454.56,
   left: 305.28,
   width: 273.96,
   height: 30.12
}
,
{
   page: 2,
   name: "Age if KnownWind generators Owned Leased", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 489.48,
   left: 260.16,
   width: 42.84,
   height: 30.12
}
,
{
   page: 2,
   name: "CommentsWind generators Owned Leased", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 489.48,
   left: 305.28,
   width: 273.96,
   height: 30.12
}
,
{
   page: 2,
   name: "Age if KnownElectric Wiring or Panel", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 524.52,
   left: 260.16,
   width: 42.84,
   height: 30.12
}
,
{
   page: 2,
   name: "CommentsElectric Wiring or Panel", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 523.52,
   left: 305.28,
   width: 273.96,
   height: 30.12
}
,
{
   page: 2,
   name: "Electric Wiring or Panel12", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 557.4,
   left: 70.3637,
   width: 156.3163,
   height: 21.545
}
,
{
   page: 2,
   name: "Age if Known12", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 557.4,
   left: 259.92,
   width: 43.32,
   height: 22.2
}
,
{
   page: 2,
   name: "Comments12", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 557.4,
   left: 305.04,
   width: 274.44,
   height: 20.236
}
,
{
   page: 2,
   name: "Electric Wiring or Panel13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 580.68,
   left: 69.4691,
   width: 157.4509,
   height: 13.625
}
,
{
   page: 2,
   name: "Age if Known13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 580.68,
   left: 259.68,
   width: 43.80,
   height: 14.28
}
,
{
   page: 2,
   name: "Comments13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 580.68,
   left: 304.8,
   width: 274.92,
   height: 12.316
}
,
{
   page: 2,
   name: "Age if Known220 volt service Phase 3", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 656.52,
   left: 260.16,
   width: 42.84,
   height: 30.12
}
,
{
   page: 2,
   name: "Comments220 volt service Phase 3", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 656.52,
   left: 305.28,
   width: 273.96,
   height: 30.12
}
,
{
   page: 2,
   name: "Electrical Service Amps", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 708.84,
   left: 73.32,
   width: 121.56,
   height: 15.72
}
,
{
   page: 2,
   name: "Age if KnownElectrical Service Amps", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 689.88,
   left: 260.4,
   width: 42.36,
   height: 47.7709
}
,
{
   page: 2,
   name: "CommentsElectrical Service Amps", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 689.88,
   left: 305.52,
   width: 273.48,
   height: 47.7709
}
,
{
   page: 2,
   name: "Check Box42", 
   isText: false,
   type: "checkbox",
   top: 123.055,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 2,
   name: "Check Box43", 
   isText: false,
   type: "checkbox",
   top: 160.364,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 2,
   name: "Check Box44", 
   isText: false,
   type: "checkbox",
   top: 196.365,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 2,
   name: "Check Box45", 
   isText: false,
   type: "checkbox",
   top: 232.365,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 2,
   name: "Check Box46", 
   isText: false,
   type: "checkbox",
   top: 266.401,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 2,
   name: "Check Box47", 
   isText: false,
   type: "checkbox",
   top: 291.929,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 2,
   name: "Check Box48", 
   isText: false,
   type: "checkbox",
   top: 386.838,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 2,
   name: "Check Box49", 
   isText: false,
   type: "checkbox",
   top: 422.184,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 2,
   name: "Check Box50", 
   isText: false,
   type: "checkbox",
   top: 456.22,
   left: 233.019,
   width: 18.000,
   height: 18.00
}
,
{
   page: 2,
   name: "Check Box51", 
   isText: false,
   type: "checkbox",
   top: 453.602,
   left: 122.437,
   width: 13.091,
   height: 13.091
}
,
{
   page: 2,
   name: "Check Box52", 
   isText: false,
   type: "checkbox",
   top: 452.947,
   left: 165.328,
   width: 13.091,
   height: 13.091
}
,
{
   page: 2,
   name: "Check Box53", 
   isText: false,
   type: "checkbox",
   top: 490.911,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 2,
   name: "Check Box54", 
   isText: false,
   type: "checkbox",
   top: 526.911,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 2,
   name: "Check Box55", 
   isText: false,
   type: "checkbox",
   top: 558.984,
   left: 233.019,
   width: 18.000,
   height: 18.000
}
,
{
   page: 2,
   name: "Check Box56", 
   isText: false,
   type: "checkbox",
   top: 579.93,
   left: 235.019,
   width: 14.073,
   height: 14.073
}
,
{
   page: 2,
   name: "Check Box57", 
   isText: false,
   type: "checkbox",
   top: 487.639,
   left: 137.764,
   width: 13.091,
   height: 13.090
}
,
{
   page: 2,
   name: "Check Box58", 
   isText: false,
   type: "checkbox",
   top: 488.293,
   left: 181.037,
   width: 13.091,
   height: 13.091
}
,
{
   page: 2,
   name: "Check Box60", 
   isText: false,
   type: "checkbox",
   top: 654.548,
   left: 70.6912,
   width: 14.7273,
   height: 14.728
}
,
{
   page: 2,
   name: "Check Box61", 
   isText: false,
   type: "checkbox",
   top: 654.548,
   left: 144.655,
   width: 14.727,
   height: 14.728
}
,
{
   page: 2,
   name: "Check Box62", 
   isText: false,
   type: "checkbox",
   top: 659.13,
   left: 233.019,
   width: 18.000,
   height: 18.00
}
,
{
   page: 2,
   name: "Check Box63", 
   isText: false,
   type: "checkbox",
   top: 695.7849,
   left: 233.019,
   width: 18.000,
   height: 18.0000
}
,
{
   page: 3,
   name: "16", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 43.571,
   left: 305.674,
   width: 272.128,
   height: 41.891
}
,
{
   page: 3,
   name: "Electric Provider", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 65.64,
   left: 73.44,
   width: 139.44,
   height: 9.48
}
,
{
   page: 3,
   name: "17", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 88.713,
   left: 305.245,
   width: 272.128,
   height: 63.709
}
,
{
   page: 3,
   name: "Name of Provider", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 132.6,
   left: 73.32,
   width: 121.56,
   height: 9.48
}
,
{
   page: 3,
   name: "Telecommunication System Equipment or Service T1 fiber cable satellite Name of Provider18", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 158.52,
   left: 68.64,
   width: 157.80,
   height: 30.12
}
,
{
   page: 3,
   name: "Age If KnownElevator", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 250.32,
   left: 260.16,
   width: 42.84,
   height: 30.60
}
,
{
   page: 3,
   name: "CommentsElevator", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 250.32,
   left: 305.28,
   width: 273.96,
   height: 30.60
}
,
{
   page: 3,
   name: "Age If KnownLifts or Hoists", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 287.04,
   left: 260.16,
   width: 42.84,
   height: 30.60
}
,
{
   page: 3,
   name: "CommentsLifts or Hoists", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 287.04,
   left: 305.28,
   width: 273.96,
   height: 30.60
}
,
{
   page: 3,
   name: "Age If KnownFire suppression andor sprinkler system", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 323.76,
   left: 260.16,
   width: 42.84,
   height: 30.60
}
,
{
   page: 3,
   name: "CommentsFire suppression andor sprinkler system", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 323.76,
   left: 305.28,
   width: 273.96,
   height: 30.60
}
,
{
   page: 3,
   name: "Age If KnownEscalator", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 360.48,
   left: 260.16,
   width: 42.84,
   height: 30.60
}
,
{
   page: 3,
   name: "CommentsEscalator", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 360.48,
   left: 305.28,
   width: 273.96,
   height: 30.60
}
,
{
   page: 3,
   name: "Escalator5", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 396.2,
   left: 68.64,
   width: 157.80,
   height: 30.6
}
,
{
   page: 3,
   name: "Age If Known5", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 397.2,
   left: 260.16,
   width: 42.84,
   height: 30.6
}
,
{
   page: 3,
   name: "Comments5_2", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 397.2,
   left: 305.28,
   width: 273.96,
   height: 30.6
}
,
{
   page: 3,
   name: "Escalator6", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 431.04,
   left: 68.52,
   width: 158.04,
   height: 21.731
}
,
{
   page: 3,
   name: "Age If Known6", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 431.04,
   left: 260.16,
   width: 42.84,
   height: 21.436
}
,
{
   page: 3,
   name: "Comments6_3", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 431.04,
   left: 305.16,
   width: 274.20,
   height: 21.731
}
,
{
   page: 3,
   name: "Age If KnownHeating system", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 518.4,
   left: 260.16,
   width: 42.84,
   height: 30.6
}
,
{
   page: 3,
   name: "CommentsHeating system", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 518.4,
   left: 305.28,
   width: 274.20,
   height: 30.6
}
,
{
   page: 3,
   name: "Age If KnownEvaporative cooler", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 555.12,
   left: 260.16,
   width: 42.84,
   height: 30.60
}
,
{
   page: 3,
   name: "CommentsEvaporative cooler", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 555.12,
   left: 305.28,
   width: 274.20,
   height: 30.60
}
,
{
   page: 3,
   name: "Age If KnownWindow air conditioning units", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 591.84,
   left: 260.16,
   width: 42.84,
   height: 30.60
}
,
{
   page: 3,
   name: "CommentsWindow air conditioning units", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 591.84,
   left: 305.28,
   width: 274.20,
   height: 30.60
}
,
{
   page: 3,
   name: "Age If KnownCentral air conditioning", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 628.56,
   left: 260.16,
   width: 42.84,
   height: 30.60
}
,
{
   page: 3,
   name: "CommentsCentral air conditioning", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 628.56,
   left: 305.28,
   width: 274.20,
   height: 30.60
}
,
{
   page: 3,
   name: "Age If KnownAttic fan", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 665.28,
   left: 260.16,
   width: 42.84,
   height: 30.60
}
,
{
   page: 3,
   name: "CommentsAttic fan", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 665.28,
   left: 305.28,
   width: 274.20,
   height: 30.60
}
,
{
   page: 3,
   name: "Age If KnownVent fans", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 702,
   left: 260.16,
   width: 42.84,
   height: 30.6
}
,
{
   page: 3,
   name: "CommentsVent fans", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 702,
   left: 305.28,
   width: 274.20,
   height: 30.6
}
,
{
   page: 3,
   name: "Text15", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 43.855,
   left: 260.16,
   width: 42.84,
   height: 30.600
}
,
{
   page: 3,
   name: "Text16", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 91.637,
   left: 260.16,
   width: 42.84,
   height: 30.600
}
,
{
   page: 3,
   name: "Text17", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 158.401,
   left: 260.16,
   width: 42.84,
   height: 30.600
}
,
{
   page: 3,
   name: "Text18", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 157.092,
   left: 305.245,
   width: 272.128,
   height: 30.509
}
,
{
   page: 3,
   name: "Check Box64", 
   isText: false,
   type: "checkbox",
   top: 47.128,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 3,
   name: "Check Box65", 
   isText: false,
   type: "checkbox",
   top: 99.491,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 3,
   name: "Check Box66", 
   isText: false,
   type: "checkbox",
   top: 161.673,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 3,
   name: "Check Box67", 
   isText: false,
   type: "checkbox",
   top: 253.31,
   left: 232.71,
   width: 18.00,
   height: 18.00
}
,
{
   page: 3,
   name: "Check Box68", 
   isText: false,
   type: "checkbox",
   top: 288.656,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 3,
   name: "Check Box69", 
   isText: false,
   type: "checkbox",
   top: 326.62,
   left: 232.71,
   width: 18.00,
   height: 18.00
}
,
{
   page: 3,
   name: "Check Box70", 
   isText: false,
   type: "checkbox",
   top: 361.965,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 3,
   name: "Check Box71", 
   isText: false,
   type: "checkbox",
   top: 400.584,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 3,
   name: "Check Box72", 
   isText: false,
   type: "checkbox",
   top: 433.966,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 3,
   name: "Check Box73", 
   isText: false,
   type: "checkbox",
   top: 520.366,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 3,
   name: "Check Box74", 
   isText: false,
   type: "checkbox",
   top: 555.712,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 3,
   name: "Check Box75", 
   isText: false,
   type: "checkbox",
   top: 593.675,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 3,
   name: "Check Box76", 
   isText: false,
   type: "checkbox",
   top: 631.639,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 3,
   name: "Check Box77", 
   isText: false,
   type: "checkbox",
   top: 668.948,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 3,
   name: "Check Box78", 
   isText: false,
   type: "checkbox",
   top: 705.6031,
   left: 232.71,
   width: 18.00,
   height: 18.0000
}
,
{
   page: 4,
   name: "Humidifier", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 43.375,
   left: 305.611,
   width: 273.721,
   height: 32.072
}
,
{
   page: 4,
   name: "Air purifier", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 80.095,
   left: 305.611,
   width: 273.721,
   height: 32.072
}
,
{
   page: 4,
   name: "Fireplace", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 116.815,
   left: 305.611,
   width: 273.721,
   height: 32.072
}
,
{
   page: 4,
   name: "Fireplace insert", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 153.535,
   left: 305.611,
   width: 273.721,
   height: 32.072
}
,
{
   page: 4,
   name: "Heating Stove", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 190.255,
   left: 305.611,
   width: 273.721,
   height: 32.072
}
,
{
   page: 4,
   name: "Fuel tanks", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 226.975,
   left: 305.611,
   width: 273.721,
   height: 32.072
}
,
{
   page: 4,
   name: "Fuel tanks13", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 263.76,
   left: 68.52,
   width: 158.04,
   height: 23.28
}
,
{
   page: 4,
   name: "Fuel tanks14", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 288.12,
   left: 69.4691,
   width: 155.4869,
   height: 12.971
}
,
{
   page: 4,
   name: "Extra", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 264.153,
   left: 305.611,
   width: 273.721,
   height: 20.530
}
,
{
   page: 4,
   name: "Type", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 363.36,
   left: 94.2,
   width: 110.924,
   height: 12.24
}
,
{
   page: 4,
   name: "Fuel", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 378.96,
   left: 91.68,
   width: 112.113,
   height: 11.585
}
,
{
   page: 4,
   name: "Type_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 394.32,
   left: 94.2,
   width: 54.0,
   height: 12.24
}
,
{
   page: 4,
   name: "Fuel_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 394.32,
   left: 168.72,
   width: 49.44,
   height: 12.24
}
,
{
   page: 4,
   name: "Heating system including furnace Type Fuel Type Fuel", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 349.2,
   left: 305.611,
   width: 273.721,
   height: 60.436
}
,
{
   page: 4,
   name: "Fireplace Type", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 412.32,
   left: 132.36,
   width: 81.00,
   height: 12.24
}
,
{
   page: 4,
   name: "Fuel_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 427.68,
   left: 91.68,
   width: 121.44,
   height: 12.24
}
,
{
   page: 4,
   name: "Fireplace Type Fuel", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 412.975,
   left: 305.611,
   width: 273.721,
   height: 32.072
}
,
{
   page: 4,
   name: "Heating Stove Type", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 449.04,
   left: 150.12,
   width: 67.56,
   height: 12.24
}
,
{
   page: 4,
   name: "Fuel_4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 464.4,
   left: 91.68,
   width: 90.00,
   height: 12.24
}
,
{
   page: 4,
   name: "Heating Stove Type Fuel", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 450.695,
   left: 305.611,
   width: 273.721,
   height: 31.418
}
,
{
   page: 4,
   name: "textchimneyDate", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 512.49,
   left: 95.16,
   width: 108.12,
   height: 12.24
}
,
{
   page: 4,
   name: "When was fireplacewood stove chimneyflue last cleaned Date Do not know", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 486.065,
   left: 305.611,
   width: 273.721,
   height: 57.557
}
,
{
   page: 4,
   name: "Fuel tanks Owned Leased", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 547.615,
   left: 305.611,
   width: 273.721,
   height: 33.381
}
,
{
   page: 4,
   name: "Type_3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 614.4,
   left: 96.3818,
   width: 117.4352,
   height: 11.836
}
,
{
   page: 4,
   name: "Radiant heating system Interior Exterior Type", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 583.985,
   left: 305.611,
   width: 273.721,
   height: 47.040
}
,
{
   page: 4,
   name: "Fuel Provider", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 632.4,
   left: 127.44,
   width: 94.44,
   height: 12.24
}
,
{
   page: 4,
   name: "Fuel Provider_2", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 633.055,
   left: 305.611,
   width: 273.721,
   height: 33.381
}
,
{
   page: 4,
   name: "Fuel Provider22", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 672.72,
   left: 68.64,
   width: 157.80,
   height: 30.60
}
,
{
   page: 4,
   name: "Check Box79", 
   isText: false,
   type: "checkbox",
   top: 46.927,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 4,
   name: "Check Box80", 
   isText: false,
   type: "checkbox",
   top: 86.4,
   left: 233.674,
   width: 18.000,
   height: 18.0
}
,
{
   page: 4,
   name: "Check Box81", 
   isText: false,
   type: "checkbox",
   top: 121.746,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 4,
   name: "Check Box82", 
   isText: false,
   type: "checkbox",
   top: 164.292,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 4,
   name: "Check Box83", 
   isText: false,
   type: "checkbox",
   top: 193.746,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 4,
   name: "Check Box84", 
   isText: false,
   type: "checkbox",
   top: 229.092,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 4,
   name: "Check Box85", 
   isText: false,
   type: "checkbox",
   top: 266.401,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 4,
   name: "Check Box86", 
   isText: false,
   type: "checkbox",
   top: 287.347,
   left: 235.637,
   width: 16.691,
   height: 14.072
}
,
{
   page: 4,
   name: "Check Box87", 
   isText: false,
   type: "checkbox",
   top: 354.765,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 4,
   name: "Check Box88", 
   isText: false,
   type: "checkbox",
   top: 420.22,
   left: 233.674,
   width: 18.000,
   height: 18.00
}
,
{
   page: 4,
   name: "Check Box89", 
   isText: false,
   type: "checkbox",
   top: 452.947,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 4,
   name: "Check Box90", 
   isText: false,
   type: "checkbox",
   top: 494.184,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 4,
   name: "Check Box91", 
   isText: false,
   type: "checkbox",
   top: 549.166,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 4,
   name: "Check Box92", 
   isText: false,
   type: "checkbox",
   top: 548.821,
   left: 115.546,
   width: 12.053,
   height: 12.491
}
,
{
   page: 4,
   name: "Check Box93", 
   isText: false,
   type: "checkbox",
   top: 530.184,
   left: 72.6549,
   width: 12.0527,
   height: 12.491
}
,
{
   page: 4,
   name: "Check Box94", 
   isText: false,
   type: "checkbox",
   top: 548.821,
   left: 162.746,
   width: 12.053,
   height: 12.491
}
,
{
   page: 4,
   name: "Check Box95", 
   isText: false,
   type: "checkbox",
   top: 591.712,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 4,
   name: "Check Box96", 
   isText: false,
   type: "checkbox",
   top: 635.566,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 4,
   name: "Check Box97", 
   isText: false,
   type: "checkbox",
   top: 678.767,
   left: 233.674,
   width: 18.000,
   height: 17.9997
}
,
{
   page: 4,
   name: "Text162", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 670.912,
   left: 305.611,
   width: 273.721,
   height: 33.1274
}
,
{
   page: 4,
   name: "Check Box163", 
   isText: false,
   type: "checkbox",
   top: 601.112,
   left: 72.3821,
   width: 12.0528,
   height: 12.491
}
,
{
   page: 4,
   name: "Check Box164", 
   isText: false,
   type: "checkbox",
   top: 601.112,
   left: 122.091,
   width: 12.053,
   height: 12.491
}
,
{
   page: 4,
   name: "Text165", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 649.312,
   left: 70.6913,
   width: 149.9997,
   height: 16.109
}
,
{
   page: 4,
   name: "Text301", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 288.656,
   left: 305.674,
   width: 272.401,
   height: 12.182
}
,
{
   page: 4,
   name: "Text302", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 46.034,
   left: 261.165,
   width: 39.468,
   height: 29.833
}
,
{
   page: 4,
   name: "Text303", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 81.164,
   left: 261.165,
   width: 39.468,
   height: 29.833
}
,
{
   page: 4,
   name: "Text304", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 118.473,
   left: 261.165,
   width: 39.468,
   height: 29.833
}
,
{
   page: 4,
   name: "Text305", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 154.473,
   left: 261.165,
   width: 39.468,
   height: 29.833
}
,
{
   page: 4,
   name: "Text306", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 191.783,
   left: 261.165,
   width: 39.468,
   height: 29.833
}
,
{
   page: 4,
   name: "Text307", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 228.437,
   left: 261.165,
   width: 39.468,
   height: 29.833
}
,
{
   page: 4,
   name: "Text308", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 264.438,
   left: 261.165,
   width: 39.468,
   height: 21.129
}
,
{
   page: 4,
   name: "Text309", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 287.619,
   left: 261.165,
   width: 39.468,
   height: 13.297
}
,
{
   page: 4,
   name: "Text310", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 350.838,
   left: 261.165,
   width: 39.468,
   height: 29.833
}
,
{
   page: 4,
   name: "Text311", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 414.329,
   left: 261.165,
   width: 39.468,
   height: 29.833
}
,
{
   page: 4,
   name: "Text312", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 450.329,
   left: 261.165,
   width: 39.468,
   height: 29.833
}
,
{
   page: 4,
   name: "Text313", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 488.948,
   left: 261.165,
   width: 39.468,
   height: 29.833
}
,
{
   page: 4,
   name: "Text314", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 548.512,
   left: 261.165,
   width: 39.468,
   height: 29.833
}
,
{
   page: 4,
   name: "Text315", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 585.821,
   left: 261.165,
   width: 39.468,
   height: 29.833
}
,
{
   page: 4,
   name: "Text316", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 634.257,
   left: 261.165,
   width: 39.468,
   height: 29.833
}
,
{
   page: 4,
   name: "Text317", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 671.567,
   left: 261.165,
   width: 39.468,
   height: 29.8326
}
,
{
   page: 5,
   name: "Age If KnownWater heaters", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 91.16,
   left: 260.16,
   width: 42.84,
   height: 30.60
}
,
{
   page: 5,
   name: "CommentsWater heaters", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 91.16,
   left: 305.28,
   width: 274.20,
   height: 31.909
}
,
{
   page: 5,
   name: "Age If KnownWater filter system", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 126.36,
   left: 260.16,
   width: 42.84,
   height: 30.60
}
,
{
   page: 5,
   name: "CommentsWater filter system", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 126.36,
   left: 305.28,
   width: 274.20,
   height: 33.218
}
,
{
   page: 5,
   name: "Backflow prevention device7", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 310.32,
   left: 68.52,
   width: 158.04,
   height: 22.385
}
,
{
   page: 5,
   name: "Backflow prevention device8", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 334.44,
   left: 70.1237,
   width: 154.1783,
   height: 12.971
}
,
{
   page: 5,
   name: "Irrigation system12", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 510,
   left: 68.52,
   width: 158.04,
   height: 23.04
}
,
{
   page: 5,
   name: "Irrigation system13", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 534.12,
   left: 68.16,
   width: 158.76,
   height: 14.28
}
,
{
   page: 5,
   name: "Water heater Number of", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 585.12,
   left: 165.84,
   width: 48.84,
   height: 12.24
}
,
{
   page: 5,
   name: "Fuel type", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 600.24,
   left: 109.32,
   width: 103.56,
   height: 12.24
}
,
{
   page: 5,
   name: "Capacity", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 615.6,
   left: 107.64,
   width: 108.00,
   height: 12.24
}
,
{
   page: 5,
   name: "Master Water Shutoff Location", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 719.76,
   left: 73.32,
   width: 148.56,
   height: 12.24
}
,
{
   page: 5,
   name: "Check Box98", 
   isText: false,
   type: "checkbox",
   top: 93.6,
   left: 233.674,
   width: 18.000,
   height: 18.0
}
,
{
   page: 5,
   name: "Check Box99", 
   isText: false,
   type: "checkbox",
   top: 128.946,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 5,
   name: "Check Box100", 
   isText: false,
   type: "checkbox",
   top: 168.219,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 5,
   name: "Check Box101", 
   isText: false,
   type: "checkbox",
   top: 205.528,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 5,
   name: "Check Box102", 
   isText: false,
   type: "checkbox",
   top: 244.147,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 5,
   name: "Check Box103", 
   isText: false,
   type: "checkbox",
   top: 280.147,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 5,
   name: "Check Box104", 
   isText: false,
   type: "checkbox",
   top: 311.183,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 5,
   name: "Check Box105", 
   isText: false,
   type: "checkbox",
   top: 333.165,
   left: 233.674,
   width: 16.691,
   height: 16.036
}
,
{
   page: 5,
   name: "Check Box106", 
   isText: false,
   type: "checkbox",
   top: 402.856,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 5,
   name: "Check Box107", 
   isText: false,
   type: "checkbox",
   top: 437.238,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 5,
   name: "Check Box108", 
   isText: false,
   type: "checkbox",
   top: 476.511,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 5,
   name: "Check Box109", 
   isText: false,
   type: "checkbox",
   top: 512.511,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 5,
   name: "Check Box110", 
   isText: false,
   type: "checkbox",
   top: 532.802,
   left: 235.674,
   width: 14.076,
   height: 14.077
}
,
{
   page: 5,
   name: "Check Box111", 
   isText: false,
   type: "checkbox",
   top: 589.094,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 5,
   name: "Check Box112", 
   isText: false,
   type: "checkbox",
   top: 637.766,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 5,
   name: "Check Box113", 
   isText: false,
   type: "checkbox",
   top: 634.912,
   left: 146.655,
   width: 10.320,
   height: 10.319
}
,
{
   page: 5,
   name: "Check Box114", 
   isText: false,
   type: "checkbox",
   top: 649.93,
   left: 146.928,
   width: 10.319,
   height: 10.32
}
,
{
   page: 5,
   name: "Check Box115", 
   isText: false,
   type: "checkbox",
   top: 673.221,
   left: 131.601,
   width: 10.319,
   height: 10.319
}
,
{
   page: 5,
   name: "Check Box116", 
   isText: false,
   type: "checkbox",
   top: 673.221,
   left: 177.146,
   width: 10.320,
   height: 10.319
}
,
{
   page: 5,
   name: "Check Box117", 
   isText: false,
   type: "checkbox",
   top: 673.803,
   left: 233.674,
   width: 18.000,
   height: 18.000
}
,
{
   page: 5,
   name: "Check Box118", 
   isText: false,
   type: "checkbox",
   top: 710.1849,
   left: 233.674,
   width: 18.000,
   height: 18.0000
}
,
{
   page: 5,
   name: "Text166", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 164.292,
   left: 260.16,
   width: 42.84,
   height: 30.600
}
,
{
   page: 5,
   name: "Text167", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 200.292,
   left: 260.16,
   width: 42.84,
   height: 30.600
}
,
{
   page: 5,
   name: "Text168", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 238.256,
   left: 260.16,
   width: 42.84,
   height: 30.600
}
,
{
   page: 5,
   name: "Text169", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 276.219,
   left: 260.16,
   width: 42.84,
   height: 30.600
}
,
{
   page: 5,
   name: "Text170", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 310.256,
   left: 260.16,
   width: 42.84,
   height: 20.782
}
,
{
   page: 5,
   name: "Text171", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 334.474,
   left: 260.16,
   width: 42.84,
   height: 12.927
}
,
{
   page: 5,
   name: "Text172", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 400.584,
   left: 260.16,
   width: 42.84,
   height: 30.600
}
,
{
   page: 5,
   name: "Text173", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 436.584,
   left: 260.16,
   width: 42.84,
   height: 30.600
}
,
{
   page: 5,
   name: "Text174", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 473.893,
   left: 260.16,
   width: 42.84,
   height: 30.600
}
,
{
   page: 5,
   name: "Text175", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 510.548,
   left: 260.16,
   width: 42.84,
   height: 20.781
}
,
{
   page: 5,
   name: "Text176", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 534.112,
   left: 260.16,
   width: 42.84,
   height: 12.927
}
,
{
   page: 5,
   name: "Text177", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 587.784,
   left: 260.16,
   width: 42.84,
   height: 30.600
}
,
{
   page: 5,
   name: "Text178", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 634.912,
   left: 260.16,
   width: 42.84,
   height: 30.600
}
,
{
   page: 5,
   name: "Text179", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 672.221,
   left: 260.16,
   width: 42.84,
   height: 30.6001
}
,
{
   page: 5,
   name: "Text180", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 708.8759,
   left: 260.16,
   width: 42.84,
   height: 30.5999
}
,
{
   page: 5,
   name: "Text181", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 164.601,
   left: 305.28,
   width: 274.20,
   height: 31.909
}
,
{
   page: 5,
   name: "Text182", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 200.946,
   left: 305.28,
   width: 274.20,
   height: 32.564
}
,
{
   page: 5,
   name: "Text183", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 237.601,
   left: 305.28,
   width: 274.20,
   height: 32.564
}
,
{
   page: 5,
   name: "Text184", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 274.91,
   left: 305.28,
   width: 274.20,
   height: 31.909
}
,
{
   page: 5,
   name: "Text185", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 310.256,
   left: 305.28,
   width: 274.20,
   height: 21.436
}
,
{
   page: 5,
   name: "Text186", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 335.129,
   left: 305.28,
   width: 274.20,
   height: 12.272
}
,
{
   page: 5,
   name: "Text187", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 400.238,
   left: 305.28,
   width: 274.20,
   height: 30.600
}
,
{
   page: 5,
   name: "Text188", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 437.238,
   left: 305.28,
   width: 274.20,
   height: 32.564
}
,
{
   page: 5,
   name: "Text189", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 474.548,
   left: 305.28,
   width: 274.20,
   height: 31.909
}
,
{
   page: 5,
   name: "Text190", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 510.548,
   left: 305.28,
   width: 274.20,
   height: 20.781
}
,
{
   page: 5,
   name: "Text191", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 534.766,
   left: 305.28,
   width: 274.20,
   height: 12.273
}
,
{
   page: 5,
   name: "Text192", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 586.475,
   left: 305.28,
   width: 274.20,
   height: 44.346
}
,
{
   page: 5,
   name: "Text193", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 634.912,
   left: 305.28,
   width: 274.20,
   height: 31.909
}
,
{
   page: 5,
   name: "Text194", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 671.567,
   left: 305.28,
   width: 274.20,
   height: 32.5632
}
,
{
   page: 5,
   name: "Text195", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 709.5304,
   left: 305.28,
   width: 274.20,
   height: 29.9454
}
,
{
   page: 6,
   name: "Well metered", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 44.029,
   left: 306.611,
   width: 271.343,
   height: 32.073
}
,
{
   page: 6,
   name: "textDate of last service", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 108.12,
   left: 73.32,
   width: 139.56,
   height: 14.28
}
,
{
   page: 6,
   name: "undefined_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 138.84,
   left: 73.32,
   width: 139.56,
   height: 14.28
}
,
{
   page: 6,
   name: "Well Pump Date of last inspection Date of last service", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 80.4,
   left: 306.611,
   width: 271.343,
   height: 75.382
}
,
{
   page: 6,
   name: "Galvanized pipe", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 160.429,
   left: 306.611,
   width: 271.343,
   height: 32.073
}
,
{
   page: 6,
   name: "Polybutylene pipe", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 197.149,
   left: 306.611,
   width: 271.343,
   height: 32.073
}
,
{
   page: 6,
   name: "Well Pump", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 232.56,
   left: 118.92,
   width: 94.56,
   height: 12.24
}
,
{
   page: 6,
   name: "GPM", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 245.64,
   left: 97.44,
   width: 112.44,
   height: 14.28
}
,
{
   page: 6,
   name: "textDate_2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 261.24,
   left: 95.16,
   width: 117.00,
   height: 14.28
}
,
{
   page: 6,
   name: "Well Pump GPM Date", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 234.52,
   left: 306.611,
   width: 271.343,
   height: 43.767
}
,
{
   page: 6,
   name: "Cistern water storage", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 282.12,
   left: 152.16,
   width: 40.44,
   height: 14.28
}
,
{
   page: 6,
   name: "Cistern water storage gallons", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 282.589,
   left: 306.611,
   width: 271.343,
   height: 32.073
}
,
{
   page: 6,
   name: "Supplemental water purchased in past 2 years", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 319.309,
   left: 306.611,
   width: 271.343,
   height: 32.727
}
,
{
   page: 6,
   name: "Supplemental water purchased in past 2 years25", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 358.32,
   left: 68.64,
   width: 157.80,
   height: 30.60
}
,
{
   page: 6,
   name: "Well Permit", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 437.64,
   left: 456.36,
   width: 120.12,
   height: 14.28
}
,
{
   page: 6,
   name: "CommentsSewage system including sewer lines", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 543.36,
   left: 264.72,
   width: 314.76,
   height: 30.60
}
,
{
   page: 6,
   name: "CommentsLift station sewage ejector pump", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 580.08,
   left: 264.72,
   width: 314.76,
   height: 30.60
}
,
{
   page: 6,
   name: "Lift station sewage ejector pump3", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 616.8,
   left: 68.4,
   width: 162.6,
   height: 30.6
}
,
{
   page: 6,
   name: "Comments3", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 616.8,
   left: 264.72,
   width: 314.76,
   height: 30.6
}
,
{
   page: 6,
   name: "Lift station sewage ejector pump4", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 653.52,
   left: 68.4,
   width: 162.6,
   height: 30.60
}
,
{
   page: 6,
   name: "Comments4", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 653.52,
   left: 264.72,
   width: 314.76,
   height: 30.60
}
,
{
   page: 6,
   name: "Check Box119", 
   isText: false,
   type: "checkbox",
   top: 47.782,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 6,
   name: "Check Box120", 
   isText: false,
   type: "checkbox",
   top: 86.4,
   left: 232.71,
   width: 18.00,
   height: 18.0
}
,
{
   page: 6,
   name: "Check Box121", 
   isText: false,
   type: "checkbox",
   top: 164.292,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 6,
   name: "Check Box122", 
   isText: false,
   type: "checkbox",
   top: 203.565,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 6,
   name: "Check Box123", 
   isText: false,
   type: "checkbox",
   top: 240.874,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 6,
   name: "Check Box124", 
   isText: false,
   type: "checkbox",
   top: 284.729,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 6,
   name: "Check Box125", 
   isText: false,
   type: "checkbox",
   top: 322.692,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 6,
   name: "Check Box126", 
   isText: false,
   type: "checkbox",
   top: 359.347,
   left: 232.71,
   width: 18.00,
   height: 18.000
}
,
{
   page: 6,
   name: "Text127", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 44.509,
   left: 260.51,
   width: 41.345,
   height: 23.309
}
,
{
   page: 6,
   name: "Text128", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 81.164,
   left: 260.51,
   width: 41.345,
   height: 23.309
}
,
{
   page: 6,
   name: "Text129", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 162.328,
   left: 260.51,
   width: 41.345,
   height: 23.309
}
,
{
   page: 6,
   name: "Text130", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 198.983,
   left: 260.51,
   width: 41.345,
   height: 23.309
}
,
{
   page: 6,
   name: "Text131", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 235.637,
   left: 260.51,
   width: 41.345,
   height: 23.310
}
,
{
   page: 6,
   name: "Text132", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 284.074,
   left: 260.51,
   width: 41.345,
   height: 23.309
}
,
{
   page: 6,
   name: "Text133", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 322.038,
   left: 260.51,
   width: 41.345,
   height: 23.309
}
,
{
   page: 6,
   name: "Text134", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 356.074,
   left: 260.51,
   width: 41.345,
   height: 23.309
}
,
{
   page: 6,
   name: "Text135", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 356.62,
   left: 306.611,
   width: 271.343,
   height: 31.418
}
,
{
   page: 6,
   name: "Check Box136", 
   isText: false,
   type: "checkbox",
   top: 431.347,
   left: 155.164,
   width: 12.114,
   height: 12.114
}
,
{
   page: 6,
   name: "Check Box137", 
   isText: false,
   type: "checkbox",
   top: 431.347,
   left: 196.365,
   width: 12.113,
   height: 12.114
}
,
{
   page: 6,
   name: "Check Box138", 
   isText: false,
   type: "checkbox",
   top: 431.347,
   left: 257.237,
   width: 12.114,
   height: 12.114
}
,
{
   page: 6,
   name: "Check Box139", 
   isText: false,
   type: "checkbox",
   top: 431.347,
   left: 291.892,
   width: 12.114,
   height: 12.114
}
,
{
   page: 6,
   name: "Check Box140", 
   isText: false,
   type: "checkbox",
   top: 431.347,
   left: 352.183,
   width: 12.114,
   height: 12.114
}
,
{
   page: 6,
   name: "Check Box141", 
   isText: false,
   type: "checkbox",
   top: 431.347,
   left: 391.656,
   width: 12.114,
   height: 12.114
}
,
{
   page: 6,
   name: "Check Box142", 
   isText: false,
   type: "checkbox",
   top: 447.675,
   left: 298.474,
   width: 12.114,
   height: 12.113
}
,
{
   page: 6,
   name: "Check Box143", 
   isText: false,
   type: "checkbox",
   top: 447.675,
   left: 321.074,
   width: 12.114,
   height: 12.113
}
,
{
   page: 6,
   name: "Check Box144", 
   isText: false,
   type: "checkbox",
   top: 461.457,
   left: 135.528,
   width: 12.113,
   height: 12.113
}
,
{
   page: 6,
   name: "Check Box145", 
   isText: false,
   type: "checkbox",
   top: 461.457,
   left: 167.564,
   width: 12.114,
   height: 12.113
}
,
{
   page: 6,
   name: "Check Box146", 
   isText: false,
   type: "checkbox",
   top: 461.457,
   left: 338.401,
   width: 12.114,
   height: 12.113
}
,
{
   page: 6,
   name: "Check Box147", 
   isText: false,
   type: "checkbox",
   top: 461.457,
   left: 371.129,
   width: 12.113,
   height: 12.113
}
,
{
   page: 6,
   name: "Check Box318", 
   isText: false,
   type: "checkbox",
   top: 547.439,
   left: 239.341,
   width: 18.000,
   height: 18.000
}
,
{
   page: 6,
   name: "Check Box319", 
   isText: false,
   type: "checkbox",
   top: 582.252,
   left: 237.601,
   width: 18.000,
   height: 18.000
}
,
{
   page: 6,
   name: "Check Box320", 
   isText: false,
   type: "checkbox",
   top: 621.417,
   left: 238.471,
   width: 18.000,
   height: 18.000
}
,
{
   page: 6,
   name: "Check Box321", 
   isText: false,
   type: "checkbox",
   top: 654.49,
   left: 238.471,
   width: 18.000,
   height: 18.00
}
,
{
   page: 7,
   name: "Sewer line scoped Date", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 153.48,
   left: 168.6,
   width: 108.0,
   height: 11.16
}
,
{
   page: 7,
   name: "issued", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 202.2,
   left: 100.44,
   width: 139.44,
   height: 11.16
}
,
{
   page: 7,
   name: "Gray water storageuse12", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 336.84,
   left: 67.92,
   width: 212.52,
   height: 14.28
}
,
{
   page: 7,
   name: "CommentsFlooding or drainage", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 415.92,
   left: 269.28,
   width: 309.96,
   height: 30.60
}
,
{
   page: 7,
   name: "Flooding or drainage2", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 452.64,
   left: 68.4,
   width: 162.6,
   height: 30.60
}
,
{
   page: 7,
   name: "Comments2", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 452.64,
   left: 269.28,
   width: 309.96,
   height: 30.60
}
,
{
   page: 7,
   name: "Flooding or drainage3", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 486.48,
   left: 68.28,
   width: 162.84,
   height: 23.04
}
,
{
   page: 7,
   name: "Comments3_2", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 486.48,
   left: 269.16,
   width: 310.20,
   height: 23.04
}
,
{
   page: 7,
   name: "CommentsDrainage retention ponds", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 572.16,
   left: 269.28,
   width: 309.96,
   height: 30.60
}
,
{
   page: 7,
   name: "Drainage retention ponds5", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 606,
   left: 68.28,
   width: 162.84,
   height: 23.28
}
,
{
   page: 7,
   name: "Comments5_3", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 606,
   left: 269.16,
   width: 310.20,
   height: 23.28
}
,
{
   page: 7,
   name: "CommentsIncluded fixtures and equipment", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 703.68,
   left: 269.28,
   width: 310.20,
   height: 30.60
}
,
{
   page: 7,
   name: "Check Box148", 
   isText: false,
   type: "checkbox",
   top: 42.522,
   left: 192.723,
   width: 10.478,
   height: 10.478
}
,
{
   page: 7,
   name: "Check Box149", 
   isText: false,
   type: "checkbox",
   top: 42.522,
   left: 234.092,
   width: 10.478,
   height: 10.478
}
,
{
   page: 7,
   name: "Check Box150", 
   isText: false,
   type: "checkbox",
   top: 54.328,
   left: 123.091,
   width: 10.478,
   height: 10.477
}
,
{
   page: 7,
   name: "Check Box151", 
   isText: false,
   type: "checkbox",
   top: 54.328,
   left: 192.546,
   width: 10.478,
   height: 10.477
}
,
{
   page: 7,
   name: "Check Box152", 
   isText: false,
   type: "checkbox",
   top: 54.328,
   left: 232.71,
   width: 10.478,
   height: 10.477
}
,
{
   page: 7,
   name: "Check Box153", 
   isText: false,
   type: "checkbox",
   top: 102.491,
   left: 157.746,
   width: 10.478,
   height: 10.478
}
,
{
   page: 7,
   name: "Check Box154", 
   isText: false,
   type: "checkbox",
   top: 102.491,
   left: 194.819,
   width: 10.478,
   height: 10.478
}
,
{
   page: 7,
   name: "Check Box155", 
   isText: false,
   type: "checkbox",
   top: 102.491,
   left: 232.71,
   width: 10.478,
   height: 10.478
}
,
{
   page: 7,
   name: "Check Box156", 
   isText: false,
   type: "checkbox",
   top: 417.602,
   left: 238.601,
   width: 18.000,
   height: 18.000
}
,
{
   page: 7,
   name: "Check Box157", 
   isText: false,
   type: "checkbox",
   top: 454.257,
   left: 238.601,
   width: 18.000,
   height: 18.000
}
,
{
   page: 7,
   name: "Check Box158", 
   isText: false,
   type: "checkbox",
   top: 488.293,
   left: 238.601,
   width: 18.000,
   height: 18.000
}
,
{
   page: 7,
   name: "Check Box159", 
   isText: false,
   type: "checkbox",
   top: 574.693,
   left: 238.601,
   width: 18.000,
   height: 18.000
}
,
{
   page: 7,
   name: "Check Box160", 
   isText: false,
   type: "checkbox",
   top: 608.694,
   left: 238.601,
   width: 18.000,
   height: 18.000
}
,
{
   page: 7,
   name: "Check Box161", 
   isText: false,
   type: "checkbox",
   top: 706.2577,
   left: 238.601,
   width: 18.000,
   height: 18.0000
}
,
{
   page: 7,
   name: "Text196", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 128.292,
   left: 71.3458,
   width: 204.9822,
   height: 11.958
}
,
{
   page: 7,
   name: "Text197", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 41.891,
   left: 311.838,
   width: 265.855,
   height: 70.437
}
,
{
   page: 7,
   name: "Text198", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 117.164,
   left: 312.22,
   width: 265.855,
   height: 31.818
}
,
{
   page: 7,
   name: "Text199", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 153.164,
   left: 312.22,
   width: 265.855,
   height: 31.819
}
,
{
   page: 7,
   name: "Text200", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 189.819,
   left: 312.22,
   width: 265.855,
   height: 31.818
}
,
{
   page: 7,
   name: "Text201", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 227.128,
   left: 312.22,
   width: 265.855,
   height: 31.819
}
,
{
   page: 7,
   name: "Text202", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 264.438,
   left: 312.22,
   width: 265.855,
   height: 31.818
}
,
{
   page: 7,
   name: "Text203", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 301.092,
   left: 312.22,
   width: 265.855,
   height: 31.818
}
,
{
   page: 7,
   name: "Text205", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 338.092,
   left: 312.22,
   width: 265.855,
   height: 11.528
}
,
{
   page: 7,
   name: "Check Box206", 
   isText: false,
   type: "checkbox",
   top: 45.818,
   left: 285.729,
   width: 18.000,
   height: 18.000
}
,
{
   page: 7,
   name: "Check Box207", 
   isText: false,
   type: "checkbox",
   top: 120.437,
   left: 285.729,
   width: 18.000,
   height: 18.000
}
,
{
   page: 7,
   name: "Check Box208", 
   isText: false,
   type: "checkbox",
   top: 157.092,
   left: 285.729,
   width: 18.000,
   height: 18.000
}
,
{
   page: 7,
   name: "Check Box209", 
   isText: false,
   type: "checkbox",
   top: 193.746,
   left: 285.729,
   width: 18.000,
   height: 18.000
}
,
{
   page: 7,
   name: "Check Box210", 
   isText: false,
   type: "checkbox",
   top: 231.056,
   left: 285.729,
   width: 18.000,
   height: 18.000
}
,
{
   page: 7,
   name: "Check Box211", 
   isText: false,
   type: "checkbox",
   top: 267.056,
   left: 285.729,
   width: 18.000,
   height: 18.000
}
,
{
   page: 7,
   name: "Check Box212", 
   isText: false,
   type: "checkbox",
   top: 303.71,
   left: 285.729,
   width: 18.000,
   height: 18.00
}
,
{
   page: 7,
   name: "Check Box213", 
   isText: false,
   type: "checkbox",
   top: 337.747,
   left: 288.729,
   width: 11.610,
   height: 11.610
}
,
{
   page: 7,
   name: "Text299", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 238.471,
   left: 69.6266,
   width: 204.8304,
   height: 13.297
}
,
{
   page: 7,
   name: "Text300", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 274.155,
   left: 71.3672,
   width: 205.7008,
   height: 13.296
}
,
{
   page: 7,
   name: "Text322", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 66.145,
   left: 69.6266,
   width: 198.7384,
   height: 12.427
}
,
{
   page: 8,
   name: "Stains on carpet", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 42.109,
   left: 269.935,
   width: 307.976,
   height: 32.073
}
,
{
   page: 8,
   name: "Floors", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 79.829,
   left: 269.935,
   width: 307.976,
   height: 32.073
}
,
{
   page: 8,
   name: "Floors4", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 117.84,
   left: 68.64,
   width: 162.36,
   height: 30.60
}
,
{
   page: 8,
   name: "Floors5", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 151.68,
   left: 68.52,
   width: 162.60,
   height: 21.731
}
,
{
   page: 8,
   name: "CommentsZoning violation variance conditional use violation of an enforceable PUD or non conforming use", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 280.08,
   left: 269.28,
   width: 310.20,
   height: 30.60
}
,
{
   page: 8,
   name: "CommentsNotice or threat of condemnation proceedings", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 316.8,
   left: 269.28,
   width: 310.20,
   height: 30.6
}
,
{
   page: 8,
   name: "CommentsNotice of any adverse conditions from any governmental or quasigovernmental agency that have not been resolved", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 353.52,
   left: 269.28,
   width: 310.20,
   height: 30.60
}
,
{
   page: 8,
   name: "CommentsNotice of zoning action related to the Property", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 390.24,
   left: 269.28,
   width: 310.20,
   height: 30.60
}
,
{
   page: 8,
   name: "CommentsBuilding code city or county violations", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 426.96,
   left: 269.28,
   width: 310.20,
   height: 30.60
}
,
{
   page: 8,
   name: "CommentsViolation of restrictive covenants or owners association rules or regulations", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 463.68,
   left: 269.28,
   width: 310.20,
   height: 30.60
}
,
{
   page: 8,
   name: "CommentsAny building or improvements constructed within the past one year before this Date without approval by the owners association or its designated approving body", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 497.88,
   left: 269.52,
   width: 309.72,
   height: 55.32
}
,
{
   page: 8,
   name: "CommentsAny additions or alterations made with a Building Permit", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 557.52,
   left: 269.28,
   width: 310.20,
   height: 30.60
}
,
{
   page: 8,
   name: "CommentsAny additions or nonaesthetic alterations made without a Building Permit", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 594.24,
   left: 269.28,
   width: 310.20,
   height: 30.60
}
,
{
   page: 8,
   name: "CommentsNotice of ADA complaint or report", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 630.96,
   left: 269.28,
   width: 310.20,
   height: 30.60
}
,
{
   page: 8,
   name: "CommentsOther legal action", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 667.68,
   left: 269.28,
   width: 310.20,
   height: 30.60
}
,
{
   page: 8,
   name: "CommentsAny part of the Property leased to others written or oral", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 701.88,
   left: 269.52,
   width: 309.72,
   height: 35.16
}
,
{
   page: 8,
   name: "Check Box214", 
   isText: false,
   type: "checkbox",
   top: 43.855,
   left: 240.874,
   width: 18.000,
   height: 18.000
}
,
{
   page: 8,
   name: "Check Box215", 
   isText: false,
   type: "checkbox",
   top: 81.4,
   left: 240.874,
   width: 18.000,
   height: 18.0
}
,
{
   page: 8,
   name: "Check Box216", 
   isText: false,
   type: "checkbox",
   top: 121.746,
   left: 240.874,
   width: 18.000,
   height: 18.000
}
,
{
   page: 8,
   name: "Check Box217", 
   isText: false,
   type: "checkbox",
   top: 154.128,
   left: 240.874,
   width: 18.000,
   height: 18.000
}
,
{
   page: 8,
   name: "Text218", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 116.201,
   left: 269.935,
   width: 307.976,
   height: 32.072
}
,
{
   page: 8,
   name: "Text219", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 152.51,
   left: 269.935,
   width: 307.976,
   height: 19.636
}
,
{
   page: 8,
   name: "Check Box220", 
   isText: false,
   type: "checkbox",
   top: 280.801,
   left: 242.874,
   width: 18.000,
   height: 18.000
}
,
{
   page: 8,
   name: "Check Box221", 
   isText: false,
   type: "checkbox",
   top: 318.765,
   left: 242.874,
   width: 18.000,
   height: 18.000
}
,
{
   page: 8,
   name: "Check Box222", 
   isText: false,
   type: "checkbox",
   top: 354.111,
   left: 242.874,
   width: 18.000,
   height: 18.000
}
,
{
   page: 8,
   name: "Check Box223", 
   isText: false,
   type: "checkbox",
   top: 390.765,
   left: 242.874,
   width: 18.000,
   height: 18.000
}
,
{
   page: 8,
   name: "Check Box224", 
   isText: false,
   type: "checkbox",
   top: 428.729,
   left: 242.874,
   width: 18.000,
   height: 18.000
}
,
{
   page: 8,
   name: "Check Box225", 
   isText: false,
   type: "checkbox",
   top: 465.384,
   left: 242.874,
   width: 18.000,
   height: 18.000
}
,
{
   page: 8,
   name: "Check Box226", 
   isText: false,
   type: "checkbox",
   top: 502.039,
   left: 242.874,
   width: 18.000,
   height: 18.000
}
,
{
   page: 8,
   name: "Check Box227", 
   isText: false,
   type: "checkbox",
   top: 558.984,
   left: 242.874,
   width: 18.000,
   height: 18.000
}
,
{
   page: 8,
   name: "Check Box228", 
   isText: false,
   type: "checkbox",
   top: 594.984,
   left: 242.874,
   width: 18.000,
   height: 18.000
}
,
{
   page: 8,
   name: "Check Box229", 
   isText: false,
   type: "checkbox",
   top: 632.948,
   left: 242.874,
   width: 18.000,
   height: 18.000
}
,
{
   page: 8,
   name: "Check Box230", 
   isText: false,
   type: "checkbox",
   top: 668.948,
   left: 242.874,
   width: 18.000,
   height: 18.000
}
,
{
   page: 8,
   name: "Check Box231", 
   isText: false,
   type: "checkbox",
   top: 704.9485,
   left: 242.874,
   width: 18.000,
   height: 18.0000
}
,
{
   page: 9,
   name: "Archeological or historical designation on the Property", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 42.109,
   left: 269.673,
   width: 308.238,
   height: 32.073
}
,
{
   page: 9,
   name: "Threatened or Endangered species on the Property", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 78.829,
   left: 269.673,
   width: 308.238,
   height: 32.073
}
,
{
   page: 9,
   name: "Grandfathered conditions or uses", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 115.549,
   left: 269.673,
   width: 308.238,
   height: 32.073
}
,
{
   page: 9,
   name: "Grandfathered conditions or uses16", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 154.56,
   left: 68.64,
   width: 166.68,
   height: 30.60
}
,
{
   page: 9,
   name: "Grandfathered conditions or uses17", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 188.4,
   left: 68.52,
   width: 166.92,
   height: 21.731
}
,
{
   page: 9,
   name: "CommentsAny access problems issues or concerns", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 283.156,
   left: 269.28,
   width: 310.20,
   height: 32.564
}
,
{
   page: 9,
   name: "CommentsRoads trails paths or driveways through the Property used by others", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 319.222,
   left: 269.28,
   width: 310.20,
   height: 33.218
}
,
{
   page: 9,
   name: "CommentsPublic highway or county road bordering the Property", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 355.942,
   left: 269.28,
   width: 310.20,
   height: 33.218
}
,
{
   page: 9,
   name: "CommentsAny proposed or existing transportation project that affects or is expected to affect the Property", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 392.662,
   left: 269.28,
   width: 310.20,
   height: 33.218
}
,
{
   page: 9,
   name: "CommentsEncroachments boundary disputes or unrecorded easements", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 429.48,
   left: 269.52,
   width: 309.72,
   height: 33.022
}
,
{
   page: 9,
   name: "CommentsShared or common areas with adjoining properties", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 467.476,
   left: 269.28,
   width: 310.20,
   height: 32.564
}
,
{
   page: 9,
   name: "CommentsRequirements for curb gravelpaving landscaping", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 504.196,
   left: 269.28,
   width: 310.20,
   height: 32.564
}
,
{
   page: 9,
   name: "CommentsAny limitations on parking or access due to size number of vehicles or type of vehicles in the past year", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 540.36,
   left: 269.52,
   width: 309.72,
   height: 33.916
}
,
{
   page: 9,
   name: "Any limitations on parking or access due to size number of vehicles or type of vehicles in the past year9", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 577.68,
   left: 68.52,
   width: 166.92,
   height: 21.731
}
,
{
   page: 9,
   name: "Comments9", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 577.68,
   left: 269.16,
   width: 310.44,
   height: 21.076
}
,
{
   page: 9,
   name: "Any limitations on parking or access due to size number of vehicles or type of vehicles in the past year10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 603.8,
   left: 69.4691,
   width: 166.3309,
   height: 10.593
}
,
{
   page: 9,
   name: "Comments10", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 603.764,
   left: 269.16,
   width: 310.44,
   height: 10.592
}
,
{
   page: 9,
   name: "CommentsHazardous materials on the Property such as radioactive toxic or biohazardous materials asbestos pesticides herbicides wastewater sludge radon methane mill tailings solvents or petroleum products", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 686.04,
   left: 269.52,
   width: 309.72,
   height: 53.5963
}
,
{
   page: 9,
   name: "Check Box232", 
   isText: false,
   type: "checkbox",
   top: 46.473,
   left: 242.837,
   width: 18.000,
   height: 18.000
}
,
{
   page: 9,
   name: "Check Box233", 
   isText: false,
   type: "checkbox",
   top: 82.473,
   left: 242.837,
   width: 18.000,
   height: 18.000
}
,
{
   page: 9,
   name: "Check Box234", 
   isText: false,
   type: "checkbox",
   top: 120.437,
   left: 242.837,
   width: 18.000,
   height: 18.000
}
,
{
   page: 9,
   name: "Check Box235", 
   isText: false,
   type: "checkbox",
   top: 155.782,
   left: 242.837,
   width: 18.000,
   height: 18.000
}
,
{
   page: 9,
   name: "Check Box236", 
   isText: false,
   type: "checkbox",
   top: 189.819,
   left: 242.837,
   width: 18.000,
   height: 18.000
}
,
{
   page: 9,
   name: "Text237", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 151.855,
   left: 269.673,
   width: 308.238,
   height: 33.128
}
,
{
   page: 9,
   name: "Text238", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 188.51,
   left: 269.673,
   width: 308.238,
   height: 22.00
}
,
{
   page: 9,
   name: "Check Box239", 
   isText: false,
   type: "checkbox",
   top: 286.038,
   left: 242.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 9,
   name: "Check Box240", 
   isText: false,
   type: "checkbox",
   top: 324.656,
   left: 242.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 9,
   name: "Check Box241", 
   isText: false,
   type: "checkbox",
   top: 360.656,
   left: 242.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 9,
   name: "Check Box242", 
   isText: false,
   type: "checkbox",
   top: 397.311,
   left: 242.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 9,
   name: "Check Box243", 
   isText: false,
   type: "checkbox",
   top: 433.966,
   left: 242.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 9,
   name: "Check Box244", 
   isText: false,
   type: "checkbox",
   top: 471.929,
   left: 242.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 9,
   name: "Check Box245", 
   isText: false,
   type: "checkbox",
   top: 509.239,
   left: 242.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 9,
   name: "Check Box246", 
   isText: false,
   type: "checkbox",
   top: 544.584,
   left: 242.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 9,
   name: "Check Box247", 
   isText: false,
   type: "checkbox",
   top: 581.239,
   left: 242.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 9,
   name: "Check Box248", 
   isText: false,
   type: "checkbox",
   top: 602.839,
   left: 243.183,
   width: 17.601,
   height: 14.266
}
,
{
   page: 9,
   name: "Check Box249", 
   isText: false,
   type: "checkbox",
   top: 690.549,
   left: 242.528,
   width: 18.000,
   height: 17.9995
}
,
{
   page: 10,
   name: "Underground storage tanks", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 42.109,
   left: 269.673,
   width: 308.892,
   height: 32.073
}
,
{
   page: 10,
   name: "Aboveground storage tanks", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 78.829,
   left: 269.673,
   width: 308.892,
   height: 32.073
}
,
{
   page: 10,
   name: "Underground transmission lines", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 115.549,
   left: 269.673,
   width: 308.892,
   height: 32.073
}
,
{
   page: 10,
   name: "Property used as situated on or adjoining a dump landfill or municipal solid waste landfill", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 152.269,
   left: 269.673,
   width: 308.892,
   height: 31.418
}
,
{
   page: 10,
   name: "Monitoring wells or test equipment", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 188.989,
   left: 269.673,
   width: 308.892,
   height: 33.382
}
,
{
   page: 10,
   name: "Sliding settling upheaval movement or instability of earth or expansive soils on the Property", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 225.709,
   left: 269.673,
   width: 308.892,
   height: 33.382
}
,
{
   page: 10,
   name: "Mine shafts tunnels or abandoned wells on the Property", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 262.069,
   left: 269.673,
   width: 308.892,
   height: 24.873
}
,
{
   page: 10,
   name: "Within a governmentally designated geological hazard or sensitive area", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 290.695,
   left: 269.673,
   width: 308.892,
   height: 24.218
}
,
{
   page: 10,
   name: "Within a governmentally designated floodplain or wetland area", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 317.465,
   left: 269.673,
   width: 308.892,
   height: 26.182
}
,
{
   page: 10,
   name: "Dead diseased or infested trees or shrubs", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 346.855,
   left: 269.673,
   width: 308.892,
   height: 24.218
}
,
{
   page: 10,
   name: "Environmental assessments studies or reports done involving the physical condition of the Property", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 373.829,
   left: 269.673,
   width: 308.892,
   height: 32.073
}
,
{
   page: 10,
   name: "Used for any mining graveling or other natural resource extraction operations such as oil and gas wells", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 409.109,
   left: 269.673,
   width: 308.892,
   height: 32.073
}
,
{
   page: 10,
   name: "Other environmental problems issues or concerns", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 445.051,
   left: 269.673,
   width: 308.892,
   height: 24.218
}
,
{
   page: 10,
   name: "Grease or sand pits or traps", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 472.549,
   left: 269.673,
   width: 308.892,
   height: 24.873
}
,
{
   page: 10,
   name: "Grease or sand pits or traps16", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 500.52,
   left: 68.64,
   width: 166.68,
   height: 25.211
}
,
{
   page: 10,
   name: "Grease or sand pits or traps17", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 528.895,
   left: 70.2437,
   width: 165.4363,
   height: 15.796
}
,
{
   page: 10,
   name: "CommentsProperty is part of an owners association", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 620.16,
   left: 273.6,
   width: 305.88,
   height: 30.60
}
,
{
   page: 10,
   name: "CommentsSpecial assessments or increases in regular assessments approved by owners association but not yet implemented", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 656.88,
   left: 273.6,
   width: 305.88,
   height: 30.60
}
,
{
   page: 10,
   name: "CommentsProblems issues concers or defects in the Common Elements or Limited Common Elements of the Association Property", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 693.6,
   left: 273.6,
   width: 305.88,
   height: 30.6
}
,
{
   page: 10,
   name: "Check Box250", 
   isText: false,
   type: "checkbox",
   top: 44.509,
   left: 241.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 10,
   name: "Check Box251", 
   isText: false,
   type: "checkbox",
   top: 81.819,
   left: 241.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 10,
   name: "Check Box252", 
   isText: false,
   type: "checkbox",
   top: 119.782,
   left: 241.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 10,
   name: "Check Box253", 
   isText: false,
   type: "checkbox",
   top: 154.473,
   left: 241.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 10,
   name: "Check Box254", 
   isText: false,
   type: "checkbox",
   top: 193.092,
   left: 241.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 10,
   name: "Check Box255", 
   isText: false,
   type: "checkbox",
   top: 229.746,
   left: 241.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 10,
   name: "Check Box256", 
   isText: false,
   type: "checkbox",
   top: 264.438,
   left: 241.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 10,
   name: "Check Box257", 
   isText: false,
   type: "checkbox",
   top: 293.238,
   left: 241.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 10,
   name: "Check Box258", 
   isText: false,
   type: "checkbox",
   top: 321.383,
   left: 241.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 10,
   name: "Check Box259", 
   isText: false,
   type: "checkbox",
   top: 348.22,
   left: 241.528,
   width: 18.000,
   height: 18.00
}
,
{
   page: 10,
   name: "Check Box260", 
   isText: false,
   type: "checkbox",
   top: 377.02,
   left: 241.528,
   width: 18.000,
   height: 18.00
}
,
{
   page: 10,
   name: "Check Box261", 
   isText: false,
   type: "checkbox",
   top: 412.365,
   left: 241.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 10,
   name: "Check Box262", 
   isText: false,
   type: "checkbox",
   top: 446.402,
   left: 241.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 10,
   name: "Check Box263", 
   isText: false,
   type: "checkbox",
   top: 474.82,
   left: 241.528,
   width: 18.000,
   height: 18.00
}
,
{
   page: 10,
   name: "Check Box264", 
   isText: false,
   type: "checkbox",
   top: 503.348,
   left: 241.528,
   width: 18.000,
   height: 18.000
}
,
{
   page: 10,
   name: "Check Box265", 
   isText: false,
   type: "checkbox",
   top: 528.566,
   left: 242.528,
   width: 14.728,
   height: 16.036
}
,
{
   page: 10,
   name: "Text266", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 500.729,
   left: 269.673,
   width: 308.892,
   height: 23.964
}
,
{
   page: 10,
   name: "Text267", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 528.875,
   left: 269.673,
   width: 308.892,
   height: 15.455
}
,
{
   page: 10,
   name: "Check Box268", 
   isText: false,
   type: "checkbox",
   top: 620.512,
   left: 246.765,
   width: 18.000,
   height: 18.000
}
,
{
   page: 10,
   name: "Check Box269", 
   isText: false,
   type: "checkbox",
   top: 658.476,
   left: 246.765,
   width: 18.000,
   height: 18.000
}
,
{
   page: 10,
   name: "Check Box270", 
   isText: false,
   type: "checkbox",
   top: 694.4758,
   left: 246.765,
   width: 18.000,
   height: 18.0000
}
,
{
   page: 11,
   name: "Has the Association made demand or commenced a lawsuit against a builder or contractor alleging defective construction of improvements of the Association Property common area or property owned or controlled by the Association but outside the Sellers Property or unit", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 100.865,
   left: 273.698,
   width: 304.485,
   height: 83.106
}
,
{
   page: 11,
   name: "Has the Association made demand or commenced a lawsuit against a builder or contractor alleging defective construction of improvements of the Association Property common area or property owned or controlled by the Association but outside the Sellers Property or unit5", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 186.72,
   left: 68.52,
   width: 171.48,
   height: 23.28
}
,
{
   page: 11,
   name: "Has the Association made demand or commenced a lawsuit against a builder or contractor alleging defective construction of improvements of the Association Property common area or property owned or controlled by the Association but outside the Sellers Property or unit6", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 211.08,
   left: 68.16,
   width: 172.20,
   height: 14.28
}
,
{
   page: 11,
   name: "Owners Association 1", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 296.287,
   left: 73.9411,
   width: 164.6519,
   height: 11.127
}
,
{
   page: 11,
   name: "Contact InformationOwners Association 1", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 287.76,
   left: 273.6,
   width: 305.88,
   height: 30.60
}
,
{
   page: 11,
   name: "Owners Association 2", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 333.007,
   left: 73.9411,
   width: 164.6519,
   height: 11.782
}
,
{
   page: 11,
   name: "Contact InformationOwners Association 2", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 324.48,
   left: 273.6,
   width: 305.88,
   height: 30.60
}
,
{
   page: 11,
   name: "Owners Association 3", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 369.727,
   left: 73.9411,
   width: 164.6519,
   height: 11.782
}
,
{
   page: 11,
   name: "Contact InformationOwners Association 3", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 361.2,
   left: 273.6,
   width: 305.88,
   height: 30.6
}
,
{
   page: 11,
   name: "Owners Association 4", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 9,
   top: 406.447,
   left: 73.9411,
   width: 164.6519,
   height: 11.782
}
,
{
   page: 11,
   name: "Contact InformationOwners Association 4", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 397.92,
   left: 273.6,
   width: 305.88,
   height: 30.60
}
,
{
   page: 11,
   name: "CommentsWritten reports of any building site roofing soils water sewer or engineering investigations or studies of the Property", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 500.88,
   left: 273.6,
   width: 305.88,
   height: 30.60
}
,
{
   page: 11,
   name: "CommentsAny property insurance claim submitted whether paid or not", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 537.6,
   left: 273.6,
   width: 305.88,
   height: 30.6
}
,
{
   page: 11,
   name: "CommentsStructural architectural and engineering plans andor specifications for any existing improvements", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 574.32,
   left: 273.6,
   width: 305.88,
   height: 30.60
}
,
{
   page: 11,
   name: "CommentsProperty was previously used as a methamphetamine laboratory and not remediated to state standards", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 611.04,
   left: 273.6,
   width: 305.88,
   height: 30.60
}
,
{
   page: 11,
   name: "CommentsGovernment special improvements approved but not yet installed that may become a lien against the Property", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 645.24,
   left: 273.84,
   width: 305.40,
   height: 46.20
}
,
{
   page: 11,
   name: "CommentsPending 1 litigation or 2 other dispute resolution proceeding regarding the Property", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 695.76,
   left: 273.6,
   width: 305.88,
   height: 30.60
}
,
{
   page: 11,
   name: "Check Box271", 
   isText: false,
   type: "checkbox",
   top: 108.0,
   left: 246.765,
   width: 18.000,
   height: 18.0
}
,
{
   page: 11,
   name: "Check Box272", 
   isText: false,
   type: "checkbox",
   top: 188.51,
   left: 246.765,
   width: 18.000,
   height: 18.00
}
,
{
   page: 11,
   name: "Check Box273", 
   isText: false,
   type: "checkbox",
   top: 212.037,
   left: 248.765,
   width: 13.418,
   height: 13.418
}
,
{
   page: 11,
   name: "Check Box274", 
   isText: false,
   type: "checkbox",
   top: 288.656,
   left: 246.765,
   width: 18.000,
   height: 18.000
}
,
{
   page: 11,
   name: "Check Box275", 
   isText: false,
   type: "checkbox",
   top: 324.656,
   left: 246.765,
   width: 18.000,
   height: 18.000
}
,
{
   page: 11,
   name: "Check Box276", 
   isText: false,
   type: "checkbox",
   top: 362.62,
   left: 246.765,
   width: 18.000,
   height: 18.00
}
,
{
   page: 11,
   name: "Check Box277", 
   isText: false,
   type: "checkbox",
   top: 399.929,
   left: 246.765,
   width: 18.000,
   height: 18.000
}
,
{
   page: 11,
   name: "Check Box278", 
   isText: false,
   type: "checkbox",
   top: 500.729,
   left: 247.419,
   width: 18.000,
   height: 18.000
}
,
{
   page: 11,
   name: "Check Box279", 
   isText: false,
   type: "checkbox",
   top: 539.348,
   left: 247.419,
   width: 18.000,
   height: 18.000
}
,
{
   page: 11,
   name: "Check Box280", 
   isText: false,
   type: "checkbox",
   top: 577.312,
   left: 247.419,
   width: 18.000,
   height: 18.000
}
,
{
   page: 11,
   name: "Check Box281", 
   isText: false,
   type: "checkbox",
   top: 613.312,
   left: 247.419,
   width: 18.000,
   height: 18.000
}
,
{
   page: 11,
   name: "Check Box282", 
   isText: false,
   type: "checkbox",
   top: 649.966,
   left: 247.419,
   width: 18.000,
   height: 18.000
}
,
{
   page: 11,
   name: "Check Box283", 
   isText: false,
   type: "checkbox",
   top: 696.4395,
   left: 247.419,
   width: 18.000,
   height: 18.0000
}
,
{
   page: 11,
   name: "Text323", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 187.992,
   left: 273.698,
   width: 304.485,
   height: 18.518
}
,
{
   page: 11,
   name: "Text324", 
   type: "text",
   fontName: "Helvetica",
   fontSize: 10,
   top: 211.231,
   left: 273.698,
   width: 304.485,
   height: 13.297
}
,
{
   page: 12,
   name: "Signs Government or private restriction problems issues or concerns", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 42.764,
   left: 274.353,
   width: 304.485,
   height: 33.141
}
,
{
   page: 12,
   name: "Property is subject to Deed Restrictions other recorded document restrictions or Affordable Housing Restrictions", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 78.48,
   left: 274.353,
   width: 304.485,
   height: 33.142
}
,
{
   page: 12,
   name: "Property is subject to Deed Restrictions other recorded document restrictions or Affordable Housing Restrictions9", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 118.56,
   left: 68.64,
   width: 168.629,
   height: 30.60
}
,
{
   page: 12,
   name: "Property is subject to Deed Restrictions other recorded document restrictions or Affordable Housing Restrictions10", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 152.4,
   left: 68.52,
   width: 168.869,
   height: 20.422
}
,
{
   page: 12,
   name: "GENERAL  Other Information", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 241.68,
   left: 274.113,
   width: 302.347,
   height: 31.811
}
,
{
   page: 12,
   name: "Signs Owned Leased", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 205.484,
   left: 274.233,
   width: 304.070,
   height: 31.418
}
,
{
   page: 12,
   name: "Signs Owned Leased12", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 243.84,
   left: 68.64,
   width: 169.499,
   height: 30.60
}
,
{
   page: 12,
   name: "Check Box284", 
   isText: false,
   type: "checkbox",
   top: 204.874,
   left: 98.8368,
   width: 12.8012,
   height: 12.801
}
,
{
   page: 12,
   name: "Check Box285", 
   isText: false,
   type: "checkbox",
   top: 204.874,
   left: 144.619,
   width: 12.801,
   height: 12.801
}
,
{
   page: 12,
   name: "Text286", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 116.51,
   left: 274.353,
   width: 304.485,
   height: 33.141
}
,
{
   page: 12,
   name: "Text287", 
   type: "textarea",
   fontName: "Helvetica",
   fontSize: 10,
   top: 152.51,
   left: 274.353,
   width: 304.485,
   height: 21.36
}
,
{
   page: 12,
   name: "Check Box293", 
   isText: false,
   type: "checkbox",
   top: 43.2,
   left: 248.074,
   width: 18.000,
   height: 18.0
}
,
{
   page: 12,
   name: "Check Box294", 
   isText: false,
   type: "checkbox",
   top: 79.855,
   left: 248.074,
   width: 18.000,
   height: 18.000
}
,
{
   page: 12,
   name: "Check Box295", 
   isText: false,
   type: "checkbox",
   top: 117.819,
   left: 248.074,
   width: 18.000,
   height: 18.000
}
,
{
   page: 12,
   name: "Check Box296", 
   isText: false,
   type: "checkbox",
   top: 154.473,
   left: 248.074,
   width: 18.000,
   height: 18.000
}
,
{
   page: 12,
   name: "Check Box297", 
   isText: false,
   type: "checkbox",
   top: 207.492,
   left: 248.074,
   width: 18.000,
   height: 18.000
}
,
{
   page: 12,
   name: "Check Box298", 
   isText: false,
   type: "checkbox",
   top: 243.492,
   left: 248.074,
   width: 18.000,
   height: 18.000
}
] }
