import React from "react";
import { Table } from "semantic-ui-react";
import CeCertsListItem from "./CeCertsListItem";

export default function CeCertsList({ ceCerts, actionButtonMode }) {
  return (
    <>
      <Table compact sortable className="mini top margin">
        <Table.Header className="mobile hidden">
          <Table.Row className="small-header">
            <Table.HeaderCell>Course Name</Table.HeaderCell>
            <Table.HeaderCell>Number Credits</Table.HeaderCell>
            <Table.HeaderCell>Date of Certificate</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {ceCerts &&
            ceCerts.length !== 0 &&
            ceCerts.map((ceCert) => (
              <CeCertsListItem
                ceCert={ceCert}
                actionButtonMode={actionButtonMode}
                key={ceCert.id}
              />
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
