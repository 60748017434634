import { format } from "date-fns";
import React from "react";
import { Table } from "semantic-ui-react";
import CeCertsActionButtons from "../CeCertsActionButtons";
import { useMediaQuery } from "react-responsive";

export default function CeCertsListItem({ ceCert, actionButtonMode }) {
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  return (
    <Table.Row key={ceCert.id}>
      <Table.Cell>{ceCert.courseName}</Table.Cell>
      <Table.Cell>
        {ceCert.numberCredits} {isMobile && <span> Credits</span>}
      </Table.Cell>

      <Table.Cell collapsing>
        <p>
          {ceCert.dateCertificate && ceCert.dateCertificate instanceof Date
            ? format(new Date(ceCert.dateCertificate), "MM/dd/yyyy")
            : ""}
        </p>
      </Table.Cell>
      <Table.Cell collapsing>
        <CeCertsActionButtons
          ceCert={ceCert}
          actionButtonMode={actionButtonMode}
        />
      </Table.Cell>
    </Table.Row>
  );
}
