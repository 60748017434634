import React from "react";
import { useSelector } from "react-redux";
import { Image } from "semantic-ui-react";

export function FormFieldLogoDisplay({ formField, pageScale }) {
  const { doc } = useSelector((state) => state.doc);

  return (
    <>
      {formField?.value && formField?.height > 10 && (
        <Image
          src={formField.value}
          style={{
            top: `${formField.top * pageScale}px`,
            left: `${formField.left * pageScale}px`,
            height: `${formField.height * pageScale}px`,
            width: `${formField.width * pageScale}px`,
          }}
        ></Image>
      )}
      {formField?.value &&
        formField?.height > 10 &&
        doc &&
        doc.logoTopAgentInfo &&
        doc.logoTopAgentInfo.showLogoTopAgentDetails && (
          <>
            <span
              style={{
                top: `${
                  formField.top * pageScale +
                  formField.height * pageScale -
                  29 * pageScale
                }px`,
                left: `${
                  formField.left * pageScale + formField.width * pageScale + 10
                }px`,
                position: "absolute",
                color: "blue",
                fontSize: `${pageScale * 9}px`,
                lineHeight: `${pageScale * 10}px`,
                textAlign: "right",
                float: "right",
              }}
            >
              {doc.logoTopAgentInfo.teamDetails
                ? doc.logoTopAgentInfo.teamDetails && <br />
                : ""}
              <br />
              {doc.logoTopAgentInfo.agentName
                ? doc.logoTopAgentInfo.agentName
                : ""}
              &nbsp; &nbsp;
              {doc.logoTopAgentInfo.agentPhone
                ? doc.logoTopAgentInfo.agentPhone
                : ""}
              <br />
              {doc.logoTopAgentInfo.agentEmail
                ? doc.logoTopAgentInfo.agentEmail
                : ""}
            </span>
          </>
        )}
    </>
  );
}
