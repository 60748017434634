import React from "react";
import { Divider, Grid, Header, Segment } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Table } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";

export default function PaymentsDashboard() {
  const { currentUserProfile } = useSelector((state) => state.profile);

  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  return (
    <div className="main-page-wrapper">
      <Grid centered stackable className="small bottom margin">
        <Grid.Column width={9}>
          <Header color="blue" as="h2">
            Payments
          </Header>
          <p color="grey">Subscription Payments for TransActioner software.</p>

          <>
            <h2>Annual Plan</h2>
            <p color="grey">
              <i>
                For annual plans, you will need to pay each year.
                <br />
                <i>
                  Renewal payments will automatically add 12 months onto your
                  expiration date.
                </i>
              </i>
            </p>
            <p color="grey">
              <a
                href="https://buy.stripe.com/7sI3cB7ZUg3L6TC4gh"
                rel="noopener noreferrer"
                target="_blank"
              >
                Link to Pay $199 for 1 year.
              </a>{" "}
            </p>
            <h2>Monthly Plan</h2>
            <p color="grey">
              {/* ?prefilled_promo_code=SUCCESS Use the coupon promo code "SUCCESS" for $49 off the $199/year. */}
              <a
                href="https://buy.stripe.com/3cs7sR2FAbNv3Hq3ce"
                rel="noopener noreferrer"
                target="_blank"
              >
                Link to Pay monthly instead at $19/month.
              </a>
              <br /> <br />
            </p>
          </>
          <Segment clearing>
            <Grid.Column width={4}>
              <div>
                <Header color="blue" as="h2">
                  Payment History
                </Header>
                {!currentUserProfile?.payments &&
                  currentUserProfile.role === "tc" && (
                    <>
                      TransActioner is currently free for Transaction
                      Coordinators.
                    </>
                  )}
                {!currentUserProfile?.payments &&
                  currentUserProfile.role !== "tc" && (
                    <>
                      Your History of Payments is Coming Soon....
                      <br />
                    </>
                  )}
                <Table compact>
                  <Table.Header className="mobile hidden">
                    <Table.Row
                      key="tableHeadingsForPayments"
                      className="small-header"
                    >
                      <Table.HeaderCell>Amount</Table.HeaderCell>
                      <Table.HeaderCell> Date Paid</Table.HeaderCell>
                      <Table.HeaderCell>Date Expires</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {currentUserProfile?.payments?.map((payMap) => (
                      <Table.Row key={payMap.dateExpires.seconds}>
                        <Table.Cell>${payMap.amount} </Table.Cell>
                        <Table.Cell>
                          <>
                            {isMobile ? <>Date Paid: </> : ""}
                            {payMap.datePayment?.toDate()?.toDateString()}
                          </>
                        </Table.Cell>
                        <Table.Cell>
                          {isMobile ? <>Date Expires: </> : ""}
                          {payMap.dateExpires?.toDate()?.toDateString() || ""}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </Grid.Column>
            <Divider hidden />
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
}
