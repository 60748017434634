import React from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Image, Menu, Sidebar, Grid, Icon } from "semantic-ui-react";
import { signOutFirebase } from "../../app/firestore/firebaseService";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useState } from "react";
import { setVisible } from "./navSlice";
import { resetAgentUserProfile } from "../profiles/profileSlice";

function HamIcon() {
  return <i className="big bars icon inverted" />;
}

function CloseIcon() {
  return <i className="big close icon" />;
}

export default function NavBarMobile() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authenticated } = useSelector((state) => state.auth);
  const { currentUserProfile, agentUserProfile } = useSelector(
    (state) => state.profile
  );
  const { visible } = useSelector((state) => state.nav);
  const { transaction } = useSelector((state) => state.transaction);
  let { agentId } = useParams();
  const locationPath = location.pathname;
  const locationPage = locationPath?.substring(
    locationPath?.lastIndexOf("/") + 1
  );
  const [icon, setIcon] = useState(HamIcon);
  const [page, setPage] = useState(locationPage);
  let { id } = useParams();

  const hideSidebar = () => {
    setIcon(HamIcon);
    dispatch(setVisible(false));
  };
  const showSidebar = () => {
    setIcon(CloseIcon);
    dispatch(setVisible(true));
  };
  const toggleSidebar = () => {
    visible ? hideSidebar() : showSidebar();
  };

  function handleMenuClick(page) {
    setPage(page);
    toggleSidebar();
    navigate(`/transactions/${id}/${page}`);
  }

  async function handleSignOut() {
    try {
      navigate("/");
      await signOutFirebase();
    } catch (error) {
      toast.error(error.message);
    }
  }

  function handleResetAgentForManagers() {
    dispatch(resetAgentUserProfile());
    toggleSidebar();
    navigate("/overviewManager");
  }

  return (
    <>
      <Menu
        inverted
        size="tiny"
        borderless
        attached
        style={{ backgroundColor: "#1c3354" }}
      >
        <Menu.Item>
          <Image src="/assets/logo-transactioner-white-medium.png" />
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item onClick={toggleSidebar}>{icon}</Menu.Item>
        </Menu.Menu>
      </Menu>
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        vertical
        visible={visible}
        width={id && id !== currentUserProfile.id ? "wide" : "thin"}
        style={{ backgroundColor: "#1c3354" }}
      >
        <Grid style={{ display: "inline-block", height: "100%" }}>
          <Grid.Column
            width={id && id !== currentUserProfile.id ? 8 : 16}
            style={{
              backgroundColor: "#1c3354",
              height: "100%",
            }}
          >
            {authenticated && currentUserProfile && locationPage !== "sign" && (
              <>
                {currentUserProfile.role === "manager" && (
                  <>
                    <Menu.Item
                      active={location.pathname.includes("overviewManager")}
                      name="All Agents"
                      onClick={() => handleResetAgentForManagers()}
                    />
                    {agentUserProfile && (
                      <>
                        <Menu.Item>
                          <span className="bold">
                            {agentUserProfile?.firstName +
                              " " +
                              agentUserProfile?.lastName}{" "}
                            <Icon name="angle right" />
                          </span>
                        </Menu.Item>
                        <Menu.Item
                          as={NavLink}
                          onClick={toggleSidebar}
                          active={
                            location.pathname.includes("transaction") && agentId
                          }
                          to={`/agent/${agentUserProfile.userId}/transactions`}
                          name="Transactions"
                        />
                        <Menu.Item
                          as={NavLink}
                          onClick={toggleSidebar}
                          active={
                            location.pathname.includes("overview") && agentId
                          }
                          to={`/agent/${agentUserProfile.userId}/overview`}
                          name="Overview"
                        />
                      </>
                    )}
                  </>
                )}
                {currentUserProfile.role !== "manager" && (
                  <>
                    <Menu.Item
                      as={NavLink}
                      onClick={toggleSidebar}
                      active={
                        location.pathname.includes("overview") &&
                        !location.pathname.includes("transaction")
                      }
                      to={
                        currentUserProfile.role === "tc"
                          ? "/overviewTc"
                          : "/overview"
                      }
                      name="Overview"
                      data-test="overview-all-nav"
                    />
                    <Menu.Item
                      as={NavLink}
                      onClick={toggleSidebar}
                      active={location.pathname.includes("transaction")}
                      to="/transactions"
                      name="Transactions"
                      data-test="transactions-active-nav"
                    />
                  </>
                )}
                {currentUserProfile.role !== "tc" &&
                  currentUserProfile.role !== "manager" && (
                    <>
                      {/* <Menu.Item
                        as={NavLink}
                        onClick={toggleSidebar}
                        to="/tasks"
                        name="Deadlines"
                      /> */}
                      <Menu.Item
                        as={NavLink}
                        onClick={toggleSidebar}
                        to="/calendar"
                        name="Calendar"
                      />
                    </>
                  )}
                {currentUserProfile.role !== "manager" && (
                  <Menu.Item
                    as={NavLink}
                    onClick={toggleSidebar}
                    to="/people"
                    name="People"
                  />
                )}
                <Menu.Item
                  as={NavLink}
                  onClick={toggleSidebar}
                  to={`/profile/${currentUserProfile?.id}`}
                  name="Profile"
                />
                <Menu.Item
                  as={NavLink}
                  onClick={toggleSidebar}
                  to="/account"
                  name="Account"
                />
                {/* <Menu.Item
                  as={NavLink}
                  onClick={toggleSidebar}
                  to="/clauses"
                  Name="Clauses"
                /> */}
                {currentUserProfile.isAdmin && (
                  <Menu.Item
                    as={NavLink}
                    onClick={toggleSidebar}
                    to="/admin"
                    name="Admin"
                  />
                )}
                <Menu.Item
                  as={NavLink}
                  onClick={toggleSidebar}
                  to="/formTemplates"
                  name="Form Templates"
                />
                <Menu.Item
                  as={NavLink}
                  onClick={toggleSidebar}
                  to="/deadlineTemplates"
                  name="Deadline Templates"
                />
                <Menu.Item
                  as={NavLink}
                  onClick={toggleSidebar}
                  to="/taskTemplates"
                  name="Task Templates"
                />
                <Menu.Item
                  as={NavLink}
                  onClick={toggleSidebar}
                  to="/emailTemplates"
                  name="Email Templates"
                />
                <Menu.Item onClick={handleSignOut} name="Sign Out" />
              </>
            )}
          </Grid.Column>
          {id && id !== currentUserProfile.id && (
            <Grid.Column
              width={8}
              style={{
                backgroundColor: "rgb(65 104 170)",
                height: "100%",
              }}
            >
              {transaction && (
                <>
                  <Menu.Item style={{ wordBreak: "break-word" }}>
                    <p className="tiny bottom margin bold text-medium">
                      {transaction.client?.firstName}{" "}
                      {transaction.client?.lastName}
                    </p>
                    {transaction.address && (
                      <p className="zero bottom margin">
                        {transaction.address.street}
                      </p>
                    )}
                  </Menu.Item>
                  <br />
                </>
              )}
              <Menu.Item
                name="Overview"
                active={page === "overview"}
                onClick={() => handleMenuClick("overview")}
              >
                Overview
              </Menu.Item>
              <Menu.Item
                name="Parties"
                active={page === "parties"}
                onClick={() => handleMenuClick("parties")}
              >
                Parties
              </Menu.Item>
              <Menu.Item
                name="Documents"
                active={page === "documents"}
                onClick={() => handleMenuClick("documents")}
              >
                Documents
              </Menu.Item>
              {/* <Menu.Item
                name="Deadlines"
                active={page === "deadlines"}
                onClick={() => handleMenuClick("deadlines")}
              >
                Deadlines
              </Menu.Item> */}
              <Menu.Item
                name="Tasks"
                active={page === "tasks"}
                onClick={() => handleMenuClick("tasks")}
              >
                Tasks
              </Menu.Item>
              <Menu.Item
                name="Emails"
                active={page === "emails"}
                onClick={() => handleMenuClick("emails")}
              >
                Emails
              </Menu.Item>
              {/* <Menu.Item
                name="Calendar"
                active={page === "calendar"}
                onClick={() => handleMenuClick("calendar")}
              >
                Calendar
              </Menu.Item> */}
              <Menu.Item
                name="History"
                active={page === "history"}
                onClick={() => handleMenuClick("history")}
              >
                History <Icon name="history" />
              </Menu.Item>
            </Grid.Column>
          )}
        </Grid>
      </Sidebar>
    </>
  );
}
