import React from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Image, Menu, Dropdown, Icon } from "semantic-ui-react";
import { signOutFirebase } from "../../app/firestore/firebaseService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { resetAgentUserProfile } from "../profiles/profileSlice";

export default function NavBarLarge() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authenticated } = useSelector((state) => state.auth);
  const { currentUserProfile, agentUserProfile } = useSelector(
    (state) => state.profile
  );
  let { agentId } = useParams();
  const locationPath = location.pathname;
  const locationPage = locationPath?.substring(
    locationPath?.lastIndexOf("/") + 1
  );

  async function handleSignOut() {
    try {
      navigate("/");
      await signOutFirebase();
    } catch (error) {
      toast.error(error.message);
    }
  }

  function handleResetAgentForManagers() {
    dispatch(resetAgentUserProfile());
    navigate("/overviewManager");
  }

  return (
    <Menu secondary stackable className="main-menu">
      {currentUserProfile.role !== "manager" && (
        <Menu.Item
          header
          style={{ marginLeft: "0px", paddingLeft: "0px" }}
          as={Link}
          to={
            currentUserProfile && currentUserProfile.role === "tc"
              ? "/overviewTc"
              : "/overview"
          }
        >
          <Image src="/assets/logo-color-with-text-small.png" />
        </Menu.Item>
      )}
      {currentUserProfile.role === "manager" && (
        <Menu.Item
          header
          style={{ marginLeft: "0px", paddingLeft: "0px" }}
          as={Link}
          onClick={() => handleResetAgentForManagers()}
        >
          <Image src="/assets/logo-color-with-text-small.png" />
        </Menu.Item>
      )}
      {authenticated && currentUserProfile && locationPage !== "sign" && (
        <>
          {currentUserProfile.role === "manager" && (
            <>
              <Menu.Item
                active={location.pathname.includes("overviewManager")}
                name="All Agents"
                onClick={() => handleResetAgentForManagers()}
              />
              <Menu.Item
                as={NavLink}
                active={location.pathname.includes("analyticsManager")}
                name="Dashboard"
                to="/analyticsManager"
              />
              {agentUserProfile && (
                <>
                  <Menu.Item>
                    <span className="text-medium text blue bold">
                      {agentUserProfile?.firstName +
                        " " +
                        agentUserProfile?.lastName}{" "}
                      <Icon name="angle right" />
                    </span>
                  </Menu.Item>
                  <Menu.Item
                    as={NavLink}
                    active={
                      location.pathname.includes("transaction") && agentId
                        ? true
                        : false
                    }
                    to={`/agent/${agentUserProfile.userId}/transactions`}
                    name="Transactions"
                  />
                  <Menu.Item
                    as={NavLink}
                    active={
                      location.pathname.includes("overview") && agentId
                        ? true
                        : false
                    }
                    to={`/agent/${agentUserProfile.userId}/overview`}
                    name="Overview"
                  />
                </>
              )}
            </>
          )}
          {currentUserProfile.role !== "manager" && (
            <>
              <Menu.Item
                as={NavLink}
                active={
                  location.pathname.includes("overview") &&
                  !location.pathname.includes("transaction")
                }
                to={
                  currentUserProfile.role === "tc" ? "/overviewTc" : "/overview"
                }
                name="Overview"
                data-test="overview-all-nav"
              />
              <Menu.Item
                as={NavLink}
                active={location.pathname.includes("transaction")}
                to="/transactions"
                name="Transactions"
                data-test="transactions-active-nav"
              />
            </>
          )}
          {currentUserProfile.role !== "tc" &&
            currentUserProfile.role !== "manager" && (
              <>
                {/* <Menu.Item
                  as={NavLink}
                  to="/tasks"
                  name="Deadlines"
                  data-test="tasks-all-nav"
                /> */}
                <Menu.Item
                  as={NavLink}
                  to="/calendar"
                  name="Calendar"
                  data-test="calendar-all-nav"
                />
              </>
            )}
          {currentUserProfile.role !== "manager" && (
            <Menu.Item
              as={NavLink}
              to="/people"
              name="People"
              data-test="people-nav"
            />
          )}
          <Menu.Item position="right">
            <Dropdown
              pointing="top left"
              direction="left"
              text={
                currentUserProfile?.firstName +
                " " +
                currentUserProfile?.lastName
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  as={Link}
                  to={`/profile/${currentUserProfile?.id}`}
                  text="My profile"
                  icon="user outline"
                />
                <Dropdown.Item
                  as={Link}
                  to="/account"
                  text="My account"
                  icon="sun outline"
                />
                <Dropdown.Item
                  as={Link}
                  to="/clauses"
                  text="My clauses"
                  icon="edit outline"
                />
                <Dropdown.Item
                  as={Link}
                  to="/payments"
                  text="Payments"
                  icon="money bill alternate outline"
                />
                {currentUserProfile.isAdmin && (
                  <Dropdown.Item
                    as={Link}
                    to="/admin"
                    text="User Admin"
                    icon="wrench"
                  />
                )}
                <Dropdown.Item
                  as={Link}
                  to="/ceCerts"
                  text="CE Certs"
                  icon="paperclip"
                />
                <Dropdown.Divider />
                <Dropdown.Header>Templates</Dropdown.Header>
                <Dropdown.Item
                  as={Link}
                  to="/formTemplates"
                  text="Form"
                  icon="clone outline"
                />
                <Dropdown.Item
                  as={Link}
                  to="/deadlineTemplates"
                  text="Deadline"
                  icon="calendar plus outline"
                />
                <Dropdown.Item
                  as={Link}
                  to="/taskTemplates"
                  text="Task"
                  icon="list alternate outline"
                />
                <Dropdown.Item
                  as={Link}
                  to="/emailTemplates"
                  text="Email"
                  icon="mail outline"
                />
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={handleSignOut}
                  text="Sign Out"
                  icon="power"
                />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </>
      )}
    </Menu>
  );
}
