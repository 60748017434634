import {
  PDFDocument,
  rgb,
  StandardFonts,
  radians,
  PDFSignature,
} from "@cantoo/pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { format } from "date-fns";
import _ from "lodash";
import { getDocDownloadUrl } from "../firestore/firebaseService";
import { getFormFieldData } from "../../features/docs/docComponents/docFill/formFieldData/formFieldData";
import {
  docSigningVerificationEnabled,
  isDateValid,
} from "../common/util/util";

export async function loadPdf(docUrl) {
  const existingPdfBytes = await fetch(docUrl).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  return await pdfDoc.save();
}

export async function createSigningCertificatePdf(doc, currentUserProfile) {
  const startingX = 80;
  const startingY = 120;
  const secondColumnX = 320;
  const startingYSigners = 220;
  const lineSizeY = 14;
  const annotTemplate = {
    x: startingX,
    y: startingY,
    height: 10,
    page: 0,
    text: "",
    fontSize: "10",
    fontColor: "blue",
    fontFamily: "lucida console",
    type: "text",
    signed: true,
    agentsField: true,
  };
  let annots = [];
  let index = 0;
  annots[index] = _.cloneDeep(annotTemplate);
  annots[index].text = "Document Name: " + doc.name;

  annots[++index] = _.cloneDeep(annotTemplate);
  annots[index].y = startingY + lineSizeY;
  annots[index].text = "Document ID: " + doc.id;

  annots[++index] = _.cloneDeep(annotTemplate);
  annots[index].x = secondColumnX;
  annots[index].y = startingY + lineSizeY;
  annots[index].text = "Transaction ID: " + doc.transactionId;

  annots[++index] = _.cloneDeep(annotTemplate);
  annots[index].x = secondColumnX;
  annots[index].y = startingY + lineSizeY * 2;
  if (doc.numPages) {
    annots[index].text = "Number of pages: " + doc.numPages + doc.createdAt;
  } else {
    annots[index].text = " ";
  }

  annots[++index] = _.cloneDeep(annotTemplate);
  annots[index].text = "Document Owner: " + doc.userFullname;
  annots[index].y = startingY + lineSizeY * 2;

  annots[++index] = _.cloneDeep(annotTemplate);
  annots[index].text =
    "Document Created: " +
    format(new Date(doc.createdAt), "MM/dd/yyyy h:mm aa O");
  annots[index].y = startingY + lineSizeY * 3;

  let sentOutAtText = "";
  if (doc.signingSentOutAt) {
    sentOutAtText =
      "Sent out at: " +
      format(new Date(doc.signingSentOutAt), "MM/dd/yyyy h:mm aa O");
  } else {
    sentOutAtText = " ";
  }
  annots[3].text = sentOutAtText;

  let signatureAnnots =
    (doc.annotsInProgress &&
      doc.annotsInProgress.filter(
        (annot) =>
          annot.agentsField === true &&
          (annot.type === "signature" || annot.type === "initials")
      )) ||
    [];
  if (doc.annotsToSign && Object.keys(doc.annotsToSign).length !== 0) {
    const newAnnotsToSign = Object.values(_.cloneDeep(doc.annotsToSign));
    signatureAnnots = [...signatureAnnots, ...newAnnotsToSign];
  }

  if (doc.annotsByAgent && Object.keys(doc.annotsByAgent).length !== 0) {
    const newAnnotsByAgent = Object.values(_.cloneDeep(doc.annotsByAgent));
    signatureAnnots = [...signatureAnnots, ...newAnnotsByAgent];
  }

  if (signatureAnnots && signatureAnnots.length !== 0) {
    let i = 0;
    let column = 0;
    let column2StartingX = 250;
    let column2StartingY = 50;
    signatureAnnots.forEach((annot) => {
      if (
        (annot.signed || annot.agentsField) &&
        (annot.type === "signature" || annot.type === "initials") &&
        (!doc.isDuplicate ||
          (doc.duplicatedAt &&
            annot.signedAt &&
            annot.signedAt.toDate() > doc.duplicatedAt))
      ) {
        let annotOriginal = _.cloneDeep(annot);
        let annotSignatureTemplate = _.cloneDeep(annot);
        annotSignatureTemplate.page = 0;
        annotSignatureTemplate.fontSize = "20";
        annotSignatureTemplate.height = 30;
        annotSignatureTemplate.x = startingX + column * column2StartingX;
        annotSignatureTemplate.y =
          startingYSigners + (i - column) * column2StartingY + 12;
        let annotSignatureTemplateText = _.cloneDeep(annotSignatureTemplate);
        annotSignatureTemplateText.type = "text";
        annotSignatureTemplateText.fontSize = "10";
        let annotSignedAt = _.cloneDeep(annotSignatureTemplateText);
        let annotSignerRole = _.cloneDeep(annotSignatureTemplateText);
        let annotSignerName = _.cloneDeep(annotSignatureTemplateText);
        let annotSignerEmail = _.cloneDeep(annotSignatureTemplateText);
        let annotSignerIpAddress = _.cloneDeep(annotSignatureTemplateText);
        let annotSignerSecurityLevel = _.cloneDeep(annotSignatureTemplateText);
        annotSignedAt.x = startingX + column * column2StartingX;
        annotSignedAt.y =
          startingYSigners + (i - column) * column2StartingY + 22;
        let signedAtText = "";
        if (isDateValid(annot.signedAt)) {
          signedAtText =
            "Signed at: " +
            format(new Date(annot.signedAt), "MM/dd/yyyy h:mm aa O");
        } else {
          signedAtText =
            "Signed at: " +
            format(annot.signedAt.toDate(), "MM/dd/yyyy h:mm aa O");
        }
        annotSignedAt.text = signedAtText;
        annotSignedAt.x = startingX + column * column2StartingX;
        annotSignerRole.y =
          startingYSigners + (i - column) * column2StartingY + 32;
        annotSignerRole.text = "Role: " + _.upperFirst(annot.signerRole);
        annotSignerName.x = startingX + column * column2StartingX;
        annotSignerName.y =
          startingYSigners + (i - column) * column2StartingY + 42;
        if (annot.agentsField) {
          if (annot.type === "signature") {
            annotSignerName.text = "Name: " + annot.text;
          } else {
            annotSignerName.text =
              "Name: " +
              annot.createdByFirstName +
              " " +
              annot.createdByLastName;
          }
        } else {
          annotSignerName.text = "Name: " + annot.signedFullName;
        }
        annotSignerEmail.x = startingX + column * column2StartingX;
        annotSignerEmail.y =
          startingYSigners + (i - column) * column2StartingY + 52;
        annotSignerEmail.text = "Email: " + annot.signerEmail;
        annotSignerIpAddress.x = startingX + column * column2StartingX;
        annotSignerIpAddress.y =
          startingYSigners + (i - column) * column2StartingY + 62;
        annotSignerIpAddress.text = "IP Address: " + annot.signerIpAddress;

        annotSignerSecurityLevel.x = startingX + column * column2StartingX;
        annotSignerSecurityLevel.y =
          startingYSigners + (i - column) * column2StartingY + 82;
        annotSignerSecurityLevel.text =
          "Security Level: Email Verified, Account Authorization";

        annotOriginal.x = startingX + column * column2StartingX;
        annotOriginal.y =
          startingYSigners + (i - column) * column2StartingY + 72;
        annotOriginal.type = "text";
        annotOriginal.fontSize = "10";
        annotOriginal.height = 30;
        annotOriginal.text = `Page: ${annot.page + 1}, Location: (${Math.floor(
          annot.x
        )}, ${Math.floor(annot.y)})`;
        annots = [
          ...annots,
          annotSignatureTemplate,
          annotSignedAt,
          annotSignerName,
          annotSignerRole,
          annotSignerEmail,
          annotSignerIpAddress,
          annotOriginal,
          annotSignerSecurityLevel,
        ];
        i++;
        column = column === 0 ? 1 : 0;
      }
    });
  }
  const docUrl = await getDocDownloadUrl(
    "/public/signingCertificate/TransActioner-Signing-Certificate.pdf"
  );
  const existingPdfBytes = await fetch(docUrl).then((res) => res.arrayBuffer());
  let pdfDoc = await PDFDocument.load(existingPdfBytes, {
    ignoreEncryption: true,
  });
  return drawAnnotsOntoPdf(annots, doc, pdfDoc, currentUserProfile, true);
}

async function drawAnnotsOntoPdf(
  annots,
  doc,
  pdfDoc,
  currentUserProfile,
  certificateMode = false
) {
  if (annots && annots?.length !== 0) {
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    const zapfDingbatsFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);
    const docHeight = doc.dimensions?.height || 792;
    const docRotation = doc.dimensions?.rotation || 0;
    const pages = pdfDoc.getPages();
    pdfDoc.registerFontkit(fontkit);
    const urlApp =
      process.env.REACT_APP_ENVIRONMENT === "development"
        ? "http://localhost:3000"
        : "https://app.transactioner.com";
    const fontUrlBrush = `${urlApp}/assets/fonts/brush-script-mt-kursiv.ttf`;
    const fontUrlParisienne = `${urlApp}/assets/fonts/Parisienne-Regular.ttf`;
    const fontUrlLabelle = `${urlApp}/assets/fonts/LaBelleAurore-Regular.ttf`;
    const fontBytesBrush = await fetch(fontUrlBrush).then((res) =>
      res.arrayBuffer()
    );
    const fontBytesParisienne = await fetch(fontUrlParisienne).then((res) =>
      res.arrayBuffer()
    );
    const fontBytesLabelle = await fetch(fontUrlLabelle).then((res) =>
      res.arrayBuffer()
    );
    const customFontBrush = await pdfDoc.embedFont(fontBytesBrush);
    const customFontParisienne = await pdfDoc.embedFont(fontBytesParisienne);
    const customFontLabelle = await pdfDoc.embedFont(fontBytesLabelle);
    if (docSigningVerificationEnabled(doc, currentUserProfile)) {
      if (doc?.numPages > 0) {
        for (let i = 0; i < doc.numPages; i++) {
          let annot = {};
          annot.docId = doc.id;
          annot.transactionId = doc.transactionId;
          annot.userId = doc.userId;
          annot.active = true;
          annot.agentsField = true;
          annot.fontColor = "blue";
          annot.fontFamily = "lucida console";
          annot.fontSize = "12";
          annot.height = 12;
          annot.page = i;
          annot.required = true;
          annot.readOnly = true;
          annot.text = "TransActioner Verified - " + doc.id;
          annot.type = "text";
          annot.width = 156;
          annot.x = 320;
          annot.y = 30;
          annots = [...annots, annot];
        }
      }
    }
    annots.forEach((annot) => {
      if (
        !annot.agentsField &&
        !annot.signed &&
        !(annot.type === "date" && !doc.pdfBurnVersion)
      ) {
        return;
      }
      let text = "";
      const fontSize = annot.fontSize ? parseInt(annot.fontSize, 10) : 14;
      let fontFamily = timesRomanFont;
      if (annot.agentsField) {
        if (annot.type === "signature" || annot.type === "initials") {
          fontFamily = customFontBrush;
          text = annot.text;
        }
      } else {
        if (annot.type === "signature" || annot.type === "initials") {
          if (annot.signedFontFamily === "Parisienne") {
            fontFamily = customFontParisienne;
          } else if (annot.signedFontFamily === "LaBelleAurore") {
            fontFamily = customFontLabelle;
          } else {
            fontFamily = customFontBrush;
          }
          if (annot.type === "signature") {
            text = annot.signedFullName;
          } else if (annot.type === "initials") {
            text = annot.signedInitials;
          }
        }
      }
      if (annot.type === "checkbox") {
        fontFamily = zapfDingbatsFont;
        text = annot.text ? "✔" : "";
      } else if (annot.type === "date") {
        if (!doc.pdfBurnVersion) {
          text = format(new Date(), "M/d/yyyy");
        } else {
          if (annot.signedAt) {
            text = format(
              annot.signedAt && annot.signedAt.toDate(),
              "MM/dd/yyyy"
            );
          } else if (annot.text) {
            text = annot.text;
          }
        }
      } else if (!text) {
        text = annot.text;
      }
      let x = annot.x;
      let y = docHeight - annot.height - annot.y;
      let yForCalculation = annot.y + fontSize;
      if (docRotation !== 0) {
        const newCoordinates = calculateRotation(
          doc.dimensions,
          fontSize,
          x,
          yForCalculation
        );
        x = newCoordinates.x;
        y = newCoordinates.y;
      }
      const rotationRadians = (docRotation * Math.PI) / 180;
      if (certificateMode) {
        pages[0].drawText(text, {
          x: x,
          y: y,
          size: fontSize,
          font: fontFamily,
          rotate: radians(rotationRadians),
        });
      } else {
        pages[annot.page].drawText(text, {
          x: x,
          y: y,
          size: fontSize,
          font: fontFamily,
          color: rgb(0, 0, 1),
          rotate: radians(rotationRadians),
        });
      }
    });
  }
  return pdfDoc.save();
}

function calculateRotation(dimensions, fontSize, x, y) {
  var rotationRads = (dimensions.rotation * Math.PI) / 180;
  let scale = 1;

  //These coords are now from bottom/left
  var coordsFromBottomLeft = {
    x: x / scale,
  };
  if (dimensions.rotation === 90 || dimensions.rotation === 270) {
    coordsFromBottomLeft.y = dimensions.width - (y + fontSize) / scale;
  } else {
    coordsFromBottomLeft.y = dimensions.height - (y + fontSize) / scale;
  }

  var drawX = null;
  var drawY = null;
  if (dimensions.rotation === 90) {
    drawX =
      coordsFromBottomLeft.x * Math.cos(rotationRads) -
      coordsFromBottomLeft.y * Math.sin(rotationRads) +
      dimensions.width;
    drawY =
      coordsFromBottomLeft.x * Math.sin(rotationRads) +
      coordsFromBottomLeft.y * Math.cos(rotationRads);
  } else if (dimensions.rotation === 180) {
    drawX =
      coordsFromBottomLeft.x * Math.cos(rotationRads) -
      coordsFromBottomLeft.y * Math.sin(rotationRads) +
      dimensions.width;
    drawY =
      coordsFromBottomLeft.x * Math.sin(rotationRads) +
      coordsFromBottomLeft.y * Math.cos(rotationRads) +
      dimensions.height;
  } else if (dimensions.rotation === 270) {
    drawX =
      coordsFromBottomLeft.x * Math.cos(rotationRads) -
      coordsFromBottomLeft.y * Math.sin(rotationRads);
    drawY =
      coordsFromBottomLeft.x * Math.sin(rotationRads) +
      coordsFromBottomLeft.y * Math.cos(rotationRads) +
      dimensions.height;
  } else {
    //no rotation
    drawX = coordsFromBottomLeft.x;
    drawY = coordsFromBottomLeft.y;
  }
  return { x: drawX, y: drawY };
}

export async function addAnnotsAndFlattenPdf(annots, doc, currentUserProfile) {
  const docUrl = await getDocDownloadUrl(doc.docRef);
  const existingPdfBytes = await fetch(docUrl).then((res) => res.arrayBuffer());
  let pdfDoc = await PDFDocument.load(existingPdfBytes, {
    ignoreEncryption: true,
  });
  if (pdfDoc && pdfDoc.isEncrypted) {
    const password = "";
    pdfDoc = await PDFDocument.load(existingPdfBytes, {
      ignoreEncryption: true,
      password,
    });
  }
  return drawAnnotsOntoPdf(annots, doc, pdfDoc, currentUserProfile);
}

export async function fillAndFlattenPdf(doc, transaction, currentUserProfile) {
  const docUrl = await getDocDownloadUrl(doc.docRef);
  const existingPdfBytes = await fetch(docUrl).then((res) => res.arrayBuffer());
  let pdfDoc = await PDFDocument.load(existingPdfBytes, {
    ignoreEncryption: true,
  });
  if (pdfDoc && pdfDoc.isEncrypted) {
    const password = "";
    pdfDoc = await PDFDocument.load(existingPdfBytes, {
      ignoreEncryption: true,
      password,
    });
  }
  pdfDoc.registerFontkit(fontkit);
  const urlApp =
    process.env.REACT_APP_ENVIRONMENT === "development"
      ? "http://localhost:3000"
      : "https://app.transactioner.com";
  const fontUrlBrush = `${urlApp}/assets/fonts/brush-script-mt-kursiv.ttf`;
  const fontBytesBrush = await fetch(fontUrlBrush).then((res) =>
    res.arrayBuffer()
  );
  const customFontBrush = await pdfDoc.embedFont(fontBytesBrush);
  const docHeight = doc.dimensions?.height || 792;

  const formFieldData = getFormFieldData(doc.title, transaction);
  const form = pdfDoc.getForm();
  let brokerageLogoUrl = "";
  if (doc.formFieldValues) {
    Object.keys(doc.formFieldValues).forEach(async (key, index) => {
      if (doc.formFieldValues[key]) {
        if (doc.formFieldValues[key] === true) {
          form.getCheckBox(key).check();
        } else if (key === "Logo") {
          brokerageLogoUrl = doc.formFieldValues[key];
        } else {
          const textField = form.getTextField(key);
          const fontSize = formFieldData.filter(
            (field) => field.name === key
          )[0]?.fontSize;
          if (fontSize) {
            textField.setFontSize(fontSize);
          }
          if (
            formFieldData.filter((field) => field.name === key)[0]?.type ===
            "date"
          ) {
            const dateFormatted = format(
              doc.formFieldValues[key].toDate(),
              "M/d/yyyy"
            );
            textField.setText(dateFormatted);
          } else {
            textField.setText(doc.formFieldValues[key]);
          }
          if (
            formFieldData.filter((field) => field.name === key)[0]?.type ===
            "signature"
          ) {
            textField.defaultUpdateAppearances(customFontBrush);
          }
        }
      }
    });
  }

  const formFields = form.getFields();
  for (const field of formFields) {
    if (field instanceof PDFSignature) {
      while (field.acroField.getWidgets().length) {
        field.acroField.removeWidget(0);
      }
      form.removeField(field);
    }
  }

  form.flatten();
  if (brokerageLogoUrl) {
    const arrayBufferBrokerageLogo = await fetch(brokerageLogoUrl).then((res) =>
      res.arrayBuffer()
    );
    const imageBrokerageLogo = await pdfDoc.embedPng(arrayBufferBrokerageLogo);
    const logoFormField = {
      ...formFieldData.filter((field) => field.type === "logo")[0],
    };
    pdfDoc.getPages()[0].drawImage(imageBrokerageLogo, {
      x: logoFormField.left,
      y: docHeight - logoFormField.top - logoFormField.height,
      width: logoFormField.width,
      height: logoFormField.height,
    });

    if (doc?.logoTopAgentInfo?.showLogoTopAgentDetails ) {
       let textLogoBlockY = docHeight - logoFormField.top - 12;

      if (doc.logoTopAgentInfo.teamDetails) {
        pdfDoc.getPages()[0].drawText(doc.logoTopAgentInfo.teamDetails, {
          x: logoFormField.left + logoFormField.width + 10,
          y: textLogoBlockY,
          color: rgb(0, 0, 1),
          size: 9,
        });
        textLogoBlockY -= 9;
      }
      if (doc.logoTopAgentInfo.agentName) {
        let agentNamePhone = doc.logoTopAgentInfo.agentName;
        if (doc.logoTopAgentInfo.agentPhone) {
          agentNamePhone = agentNamePhone + '  ' + doc.logoTopAgentInfo.agentPhone;
        }
        pdfDoc.getPages()[0].drawText(agentNamePhone, {
          x: logoFormField.left + logoFormField.width + 10,
          y: textLogoBlockY,
          color: rgb(0, 0, 1),
          size: 9,
        });
      textLogoBlockY -= 9;
      }

    if (doc.logoTopAgentInfo.agentEmail) {
        pdfDoc.getPages()[0].drawText(doc.logoTopAgentInfo.agentEmail, {
          x: logoFormField.left + logoFormField.width + 10,
          y: textLogoBlockY,
          color: rgb(0, 0, 1),
          size: 9,
        });
        textLogoBlockY -= 9;
      }
    }
    }
      
  
  let annots = [];
  annots =
    (doc.annotsInProgress &&
      doc.annotsInProgress.filter((annot) => annot.agentsField === true)) ||
    [];
  if (doc.pdfBurnVersion) {
    const annotsToSign = doc.annotsToSign
      ? Object.values(doc.annotsToSign)
      : [];
    const annotsByAgent = doc.annotsByAgent
      ? Object.values(doc.annotsByAgent)
      : [];
    annots = [...annots, ...annotsToSign, ...annotsByAgent];
  }
  return drawAnnotsOntoPdf(annots, doc, pdfDoc, currentUserProfile);
}
