import React from "react";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../app/common/modals/modalSlice";
import { useMediaQuery } from "react-responsive";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { fetchCeCerts } from "./ceCertsSlice";
import { fetchCeCertsFromDb } from "../../app/firestore/firestoreService";
import useFirestoreCollectionNoAsync from "../../app/hooks/useFirestoreCollectionNoAsync";
import CeCertsList from "./ceCertsComponents/CeCertsList";
import { Table } from "semantic-ui-react";

export default function CeCertsDashboard() {
  const dispatch = useDispatch();
  const { ceCerts } = useSelector((state) => state.ceCerts);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  useFirestoreCollectionNoAsync({
    query: () => fetchCeCertsFromDb(),
    data: (ceCerts) => dispatch(fetchCeCerts(ceCerts)),
    deps: [dispatch],
  });

  if (!ceCerts) {
    return <LoadingComponent />;
  }
  function getNumCreditsForYear(year) {
    let ceCertsListForYear = ceCerts.filter(
      (ceCert) => ceCert.dateCertificate.getFullYear() === year
    );
    let numCreds = 0;
    ceCertsListForYear.forEach((ceCert) => {
      numCreds += ceCert.numberCredits;
    });
    return numCreds;
  }

  return (
    <div className="main-page-wrapper">
      <Grid centered stackable className="small bottom margin">
        <Grid.Column width={8}>
          <Header color="blue" as="h2">
            Continuing Education CE Certificates
          </Header>
          <p color="grey">Keep track of your continuing education credits.</p>
          {ceCerts?.length > 0 && (
            <>
              <Table compact className="mobile hidden">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      {new Date().getFullYear()}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {new Date().getFullYear() - 1}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {new Date().getFullYear() - 2}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {new Date().getFullYear() - 3}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      &nbsp; {getNumCreditsForYear(new Date().getFullYear())}
                    </Table.Cell>
                    <Table.Cell>
                      &nbsp;{" "}
                      {getNumCreditsForYear(new Date().getFullYear() - 1)}
                    </Table.Cell>
                    <Table.Cell>
                      &nbsp;{" "}
                      {getNumCreditsForYear(new Date().getFullYear() - 2)}
                    </Table.Cell>
                    <Table.Cell>
                      &nbsp;{" "}
                      {getNumCreditsForYear(new Date().getFullYear() - 3)}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </>
          )}
          <Segment clearing>
            <Grid.Column width={10} className="large bottom margin">
              <div className={isMobile ? null : "float-right"}>
                <Button
                  to="#"
                  icon="plus"
                  size="small"
                  className={isMobile ? "fluid" : null}
                  onClick={() =>
                    dispatch(
                      openModal({
                        modalType: "CeCertsEdit",
                        modalProps: { ceCert: null },
                      })
                    )
                  }
                  content="Add CE Certificate"
                  color="blue"
                />
              </div>
            </Grid.Column>
            <br />
            <Divider hidden className="large vertical margin" />
            <Grid.Column width={8}>
              {ceCerts?.length > 0 ? (
                <CeCertsList ceCerts={ceCerts} />
              ) : (
                <p>There are no CE certificates.</p>
              )}
            </Grid.Column>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
}
