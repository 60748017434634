import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Message, MessageHeader } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  filterRecentTrans,
  filterUpcomingDocs,
} from "../../../app/common/util/util";
import { openModal } from "../../../app/common/modals/modalSlice";
import TransactionCardList from "../transactionComponents/TransactionCardList";
import { useMediaQuery } from "react-responsive";
import DocAllAwaitingSignatureList from "../../docs/docAll/DocAllAwaitingSignatureList";
import { TaskAllCalendarWeekly } from "../../tasks/taskAll/TaskAllCalendarWeekly";

export default function TransactionAllOverview() {
  const dispatch = useDispatch();
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { transActive } = useSelector((state) => state.transaction);
  const { docsUpcoming } = useSelector((state) => state.doc);
  const { tasksUpcoming } = useSelector((state) => state.task);
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  if (!transActive || !docsUpcoming || !tasksUpcoming?.tasks)
    return <LoadingComponent content="Loading details..." />;

  const transActiveFiltered = filterRecentTrans(transActive);
  const docsUpcomingFiltered = filterUpcomingDocs(docsUpcoming);

  return (
    <>
      {currentUserProfile?.isTester === true && (
        <div className="text-center">
          <Message color="blue" className="zero margin">
            <MessageHeader>
              This is a test account. No emails will be sent out when sharing or
              sending documents for signing.
            </MessageHeader>
          </Message>
        </div>
      )}

      {!currentUserProfile?.isTester &&
        currentUserProfile?.type === "user" &&
        currentUserProfile?.payments &&
        new Date(
          Math.max(
            ...currentUserProfile?.payments.map(
              (item) => new Date(item.dateExpires.seconds * 1000)
            )
          )
        ) < new Date() && (
          <div className="text-center">
            <Message color="blue" className="zero margin">
              <MessageHeader>
                Thank you for using TransActioner!
                <br />
                <b>Your account has expired.</b>
                <br /> &nbsp; <br />
                <a
                  href="https://buy.stripe.com/7sI3cB7ZUg3L6TC4gh"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Click <u>here</u> to Pay $199/year for 1 year.
                </a>
                <br /> &nbsp; <br />
                <a
                  href="https://buy.stripe.com/3cs7sR2FAbNv3Hq3ce"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Click <u>here</u> to Subscribe for $19/month.
                </a>
                <br /> &nbsp; <br />
                <i>
                  <small>
                    Changes will be reflected within 48 hours after payment.
                  </small>
                </i>
              </MessageHeader>
            </Message>
          </div>
        )}
      <div className="main-page-wrapper">
        <>
          <Grid stackable className="small bottom margin">
            <Grid.Column computer={8} className="small bottom margin">
              <h3
                className="zero bottom margin"
                style={{ position: "absolute", bottom: "0" }}
              >
                Active Transactions
              </h3>
            </Grid.Column>
            <Grid.Column
              computer={8}
              mobile={16}
              className="small bottom padding"
            >
              {(currentUserProfile.role === "agent" ||
                currentUserProfile.role === "assistant") && (
                <Button
                  color="blue"
                  to="#"
                  floated={isMobile ? "left" : "right"}
                  icon="plus"
                  size="small"
                  className={isMobile ? "fluid" : null}
                  onClick={() =>
                    dispatch(
                      openModal({
                        modalType: "TransactionForm",
                      })
                    )
                  }
                  content="New Transaction"
                  data-test="newTransaction"
                />
              )}
            </Grid.Column>
            <Grid.Column computer={16}>
              {transActive.length > 0 ? (
                <TransactionCardList transactions={transActiveFiltered} />
              ) : (
                <p>There are no recent transactions.</p>
              )}
            </Grid.Column>
          </Grid>
          <Grid className="medium bottom margin">
            <Grid.Column computer={16}>
              <h3 style={{ marginBottom: "36px" }}>Calendar</h3>
              <div className="small horizontal padding">
                <TaskAllCalendarWeekly />
              </div>
            </Grid.Column>
          </Grid>
          <Grid computer={16}>
            <Grid.Column>
              <h3 className="mini bottom margin">
                Documents Awaiting Signature
              </h3>
              {docsUpcomingFiltered.length > 0 ? (
                <DocAllAwaitingSignatureList docs={docsUpcomingFiltered} />
              ) : (
                <p>There are no documents awaiting signature</p>
              )}
            </Grid.Column>
          </Grid>
        </>
      </div>
    </>
  );
}
