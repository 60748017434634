export function billofSaleContract() {
   return [   //2023
   {
      page: 0,
      name: "Logo",
      isText: false,
      fontSize: 10,
      type: "logo",
      top: 28.673,
      left: 52.9457,
      width: 200,
      height: 30,
    }
    ,
    {
      page: 0,
      name: "Dollars",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 123.185,
      left: 256.829,
      width: 253.654,
      height: 12.370
   }
   ,
   {
      page: 0,
      name: "Buyer the",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 136.085,
      left: 181.2,
      width: 170.13,
      height: 11.595
   }
   ,
   {
      page: 0,
      name: "receipt of which is hereby acknowledged does sell assign transfer and",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 136.0,
      left: 395.84,
      width: 65.28,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "convey to Buyer the following personal property located at",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 154.037,
      left: 71.071,
      width: 244.746,
      height: 11.440
   }   
   ,
   {
      page: 0,
      name: "undefined",
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 177.664,
      left: 72.8325,
      width: 425.0135,
      height: 10.162
   }   
   ,
   {
      page: 0,
      name: "Solar Panels", 
      isText: false,
      type: "checkbox",
      top: 214.68,
      left: 428.64,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "Water", 
      isText: false,
      type: "checkbox",
      top: 214.68,
      left: 499.92,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "Security Systems", 
      isText: false,
      type: "checkbox",
      top: 226.2,
      left: 117.72,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "Satellite Systems including satellite dishes", 
      isText: false,
      type: "checkbox",
      top: 226.2,
      left: 209.28,
      width: 9.96,
      height: 9.96
   }
   ,
   {
      page: 0,
      name: "Buy and Sell Real Estate between the Buyer and Seller for the Property storm windows storm doors window and", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 247.68,
      left: 339.84,
      width: 90.00,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "garage door remote controls and all keys", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 294,
      left: 72,
      width: 30,
      height: 10.32
   }
   ,
   {
      page: 0,
      name: "The personal property is in an As Is condition Where Is and With All Faults", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 455.16,
      left: 414.12,
      width: 126.12,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "without any representations or warranties of any kind except", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 466.68,
      left: 72,
      width: 108,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "date", 
      type: "text",
      fontName: "Helvetica",
      fontSize: 10,
      top: 630.64,
      left: 359.64,
      width: 108.48,
      height: 10.68
   }
   ,
   {
      page: 0,
      name: "Text19", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 8,
      top: 190.474,
      left: 72.897,
      width: 471.687,
      height: 24.387
   }
   ,
   {
      page: 0,
      name: "Text20", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 329.238,
      left: 72.6549,
      width: 473.2381,
      height: 46.473
   }
   ,
   {
      page: 0,
      name: "Text21", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 399.275,
      left: 72.6549,
      width: 469.3111,
      height: 45.163
   }
   ,
   {
      page: 0,
      name: "Text22", 
      type: "textarea",
      fontName: "Helvetica",
      fontSize: 10,
      top: 489.602,
      left: 72.6549,
      width: 473.8931,
      height: 56.977
   }
   ] }
   