import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Image } from "semantic-ui-react";

export function FormFieldLogo({
  formField,
  index,
  populatingFieldsStatus,
  newPopulatedFields,
}) {
  const { pageScaleFill } = useSelector((state) => state.annot);
  const { doc } = useSelector((state) => state.doc);
  const [value, setValue] = useState(
    (doc.formFieldValues && doc.formFieldValues[formField.name]) || ""
  );

  const pageScale = pageScaleFill;

  // This is a catch for prepopulating data to make sure it rerenders
  useEffect(() => {
    if (
      populatingFieldsStatus === "Complete" &&
      newPopulatedFields?.[formField.name]
    ) {
      setValue(newPopulatedFields[formField.name]);
    }
  }, [populatingFieldsStatus, formField.name, newPopulatedFields]);

  return (
    <>
      {value && formField?.height > 10 && (
        <Image
          id={index}
          src={value}
          value="logo"
          style={{
            top: `${formField.top * pageScale}px`,
            left: `${formField.left * pageScale}px`,

            height: `${formField.height * pageScale}px`,
            width: `${formField.width * pageScale}px`,
          }}
        ></Image>
      )}
      {value &&
        formField?.height > 10 &&
        doc.logoTopAgentInfo &&
        doc.logoTopAgentInfo.showLogoTopAgentDetails && (
          <>
            <span
              style={{
                top: `${
                  formField.top * pageScale +
                  formField.height * pageScale -
                  29 * pageScale
                }px`,
                left: `${
                  formField.left * pageScale + formField.width * pageScale + 10
                }px`,
                position: "absolute",
                color: "blue",
                fontSize: `${pageScale * 9}px`,
                lineHeight: `${pageScale * 10}px`,
                textAlign: "right",
                float: "right",
              }}
            >
              {doc.logoTopAgentInfo.teamDetails
                ? doc.logoTopAgentInfo.teamDetails && <br />
                : ""}
              {doc.logoTopAgentInfo.agentName
                ? doc.logoTopAgentInfo.agentName
                : ""}
              &nbsp; &nbsp;
              {doc.logoTopAgentInfo.agentPhone
                ? doc.logoTopAgentInfo.agentPhone
                : ""}
              <br />
              {doc.logoTopAgentInfo.agentEmail
                ? doc.logoTopAgentInfo.agentEmail
                : ""}
            </span>
          </>
        )}
    </>
  );
}
