import { Formik, Form } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import {
  addCeCertToDb,
  updateCeCertInDb,
} from "../../../app/firestore/firestoreService";
import { closeModal } from "../../../app/common/modals/modalSlice";
import ModalWrapper from "../../../app/common/modals/modalWrapper";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyNumberInput from "../../../app/common/form/MyNumberInput";
import MyDateInput from "../../../app/common/form/MyDateInput";

export default function CeCertsEdit({ ceCert }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  let initialValues = ceCert
    ? ceCert
    : {
        courseName: "",
        numberCredits: "",
        dateCertificate: "",
      };

  const validationSchema = Yup.object({
    courseName: Yup.string().required("You must provide a course name"),
  });

  return (
    <ModalWrapper size="small">
      <Segment clearing>
        <div className="medium horizontal margin small top margin">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                ceCert
                  ? await updateCeCertInDb(values.id, values)
                  : await addCeCertToDb(values);
                setSubmitting(false);
                toast.success("CE Certificate successfully updated");
                dispatch(
                  closeModal({
                    modalType: "CeCertsEdit",
                  })
                );
              } catch (error) {
                toast.error(error.message);
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, dirty, isValid, values }) => (
              <Form className="ui form" autoComplete="off">
                <Header size="large" color="blue">
                  {ceCert ? "Edit CE Cert" : "Create CE Cert"}
                </Header>
                <Divider />
                <Grid>
                  <Grid.Row className="small vertical padding">
                    <Grid.Column width={16}>
                      <Header
                        as="h5"
                        color="blue"
                        className="mini bottom margin"
                      >
                        CE Certificate Course Name
                      </Header>
                      <MyTextInput
                        name="courseName"
                        className="small bottom margin"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row className="small vertical padding">
                    <Grid.Column width={5} className="small bottom margin">
                      <Header
                        as="h5"
                        color="blue"
                        className="small bottom margin"
                      >
                        Number of Credits
                      </Header>
                      <MyNumberInput
                        name="numberCredits"
                        className="small bottom margin"
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Header
                        as="h5"
                        color="blue"
                        className="small bottom margin"
                      >
                        Date of Certificate
                      </Header>
                      <MyDateInput
                        name="dateCertificate"
                        placeholder="Date of Certificate"
                        dateFormat="MMMM d, yyyy"
                      />
                    </Grid.Column>
                    <Grid.Column width={8}></Grid.Column>
                  </Grid.Row>
                </Grid>
                <Divider className="medium top margin" />
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  floated={isMobile ? null : "right"}
                  primary
                  content="Submit"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
                <Button
                  disabled={isSubmitting}
                  onClick={() =>
                    dispatch(
                      closeModal({
                        modalType: "CeCertsEdit",
                      })
                    )
                  }
                  to="#"
                  type="button"
                  floated={isMobile ? null : "right"}
                  content="Cancel"
                  className={isMobile ? "fluid medium bottom margin" : null}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Segment>
    </ModalWrapper>
  );
}
